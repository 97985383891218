import React from 'react';
import { Box, Typography, Grid, InputAdornment } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { StyledTextField } from '../../components/StyledTextField';
import LockIcon from '@mui/icons-material/Lock';

const useHelperTextStyles = makeStyles(() => ({
	root: {
		marginLeft: 0,
	},
}));

export const BasicDetails = ({
	basicDetailsError,
	businessName,
	setBusinessName,
	phoneNumber,
	setPhoneNumber,
	website,
	email,
	setEmail,
	setWebsite,
	tinNum,
	setTinNum,
	focusCounter,
	focusTracker,
	setFocusCounter,
	setFocusTracker,
}) => {
	const helperTextStyles = useHelperTextStyles();
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
			<Typography
				component="h3"
				variant="h3"
				style={{
					marginBottom: '10px',
					textAlign: 'left',
					width: '100%',
					fontSize: '32px',
					fontWeight: 700,
				}}>
				Tell us about your business
			</Typography>
			<Typography
				component="h5"
				variant="h5"
				style={{
					marginBottom: '24px',
					color: '#484848',
					textAlign: 'left',
					width: '100%',
					fontWeight: 400,
				}}>
				We are excited to get you onboarded with us!
			</Typography>
			<Box sx={{ mt: 1, width: '100%' }}>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							Legal business name
						</Typography>
						<StyledTextField
							sx={{ mt: 0.5 }}
							margin="none"
							fullWidth
							required
							id="business-name"
							placeholder="Company, Inc."
							onBlur={() => {
								setFocusTracker({ ...focusCounter, businessName: false });
								setFocusCounter({ ...focusCounter, businessName: 1 });
							}}
							onFocus={() => setFocusTracker({ ...focusCounter, businessName: true })}
							error={
								basicDetailsError['businessName']?.error &&
								focusCounter['businessName'] >= 1 &&
								!focusTracker['businessName']
							}
							helperText={
								basicDetailsError['businessName']?.error &&
								focusCounter['businessName'] >= 1 &&
								!focusTracker['businessName']
									? basicDetailsError['businessName'].message
									: null
							}
							FormHelperTextProps={{
								classes: {
									root: helperTextStyles.root,
								},
							}}
							value={businessName}
							onChange={(e) => setBusinessName(e.target.value)}
							name="business-name"
							autoComplete="business-name"
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							Company website
						</Typography>
						<StyledTextField
							sx={{ mt: 0.5 }}
							margin="none"
							fullWidth
							id="website"
							onBlur={() => {
								setFocusTracker({ ...focusCounter, website: false });
								setFocusCounter({ ...focusCounter, website: 1 });
							}}
							onFocus={() => setFocusTracker({ ...focusCounter, website: true })}
							error={
								basicDetailsError['website']?.error &&
								focusCounter['website'] >= 1 &&
								!focusTracker['website']
							}
							helperText={
								basicDetailsError['website']?.error &&
								focusCounter['website'] >= 1 &&
								!focusTracker['website']
									? basicDetailsError['website'].message
									: null
							}
							FormHelperTextProps={{
								classes: {
									root: helperTextStyles.root,
								},
							}}
							placeholder="www.example.com"
							name="website"
							autoComplete="website"
							value={website}
							onChange={(e) => setWebsite(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							Company Email
						</Typography>
						<StyledTextField
							sx={{ mt: 0.5 }}
							margin="none"
							fullWidth
							id="email"
							onBlur={() => {
								setFocusTracker({ ...focusCounter, email: false });
								setFocusCounter({ ...focusCounter, email: 1 });
							}}
							onFocus={() => setFocusTracker({ ...focusCounter, email: true })}
							error={
								basicDetailsError['email']?.error &&
								focusCounter['email'] >= 1 &&
								!focusTracker['email']
							}
							helperText={
								basicDetailsError['email']?.error &&
								focusCounter['email'] >= 1 &&
								!focusTracker['email']
									? basicDetailsError['email'].message
									: null
							}
							FormHelperTextProps={{
								classes: {
									root: helperTextStyles.root,
								},
							}}
							placeholder="mail@example.com"
							name="email"
							autoComplete="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							Phone number
						</Typography>
						<StyledTextField
							sx={{ mt: 0.5 }}
							margin="none"
							fullWidth
							onBlur={() => {
								setFocusTracker({ ...focusCounter, phoneNumber: false });
								setFocusCounter({ ...focusCounter, phoneNumber: 1 });
							}}
							onFocus={() => setFocusTracker({ ...focusCounter, phoneNumber: true })}
							error={
								basicDetailsError['phoneNumber']?.error &&
								(focusCounter['phoneNumber'] >= 1 || phoneNumber.length > 0)
							}
							id="phone-number"
							placeholder="4155552671"
							InputProps={{
								startAdornment: <InputAdornment position="start">+1</InputAdornment>,
							}}
							helperText={
								basicDetailsError['phoneNumber']?.error &&
								(focusCounter['phoneNumber'] >= 1 || phoneNumber.length > 0)
									? basicDetailsError['phoneNumber'].message
									: null
							}
							FormHelperTextProps={{
								classes: {
									root: helperTextStyles.root,
								},
							}}
							name="phone-number"
							autoComplete="phone-number"
							value={phoneNumber}
							onChange={(e) => {
								if (e.target.value.length < 13) {
									let cleaned = ('' + e.target.value).replace(/\D/g, '');
									let normValue = `${cleaned.substring(0, 3)}${
										cleaned.length > 3 ? '-' : ''
									}${cleaned.substring(3, 6)}${cleaned.length > 6 ? '-' : ''}${cleaned.substring(
										6,
										11
									)}`;

									setPhoneNumber(normValue);
								}
							}}
						/>
					</Grid>
					{/* 					<Grid item xs={12}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							Federal TIN number
						</Typography>
						<StyledTextField
							sx={{ mt: 0.5 }}
							margin="none"
							fullWidth
							onBlur={() => {
								setFocusTracker({ ...focusCounter, tinNum: false });
								setFocusCounter({ ...focusCounter, tinNum: 1 });
							}}
							onFocus={() => setFocusTracker({ ...focusCounter, tinNum: true })}
							error={
								basicDetailsError['tinNum']?.error && (focusCounter['tinNum'] >= 1 || tinNum.length > 0)
							}
							id="tin-number"
							placeholder="XXXXXXXXX"
							helperText={
								basicDetailsError['tinNum']?.error && (focusCounter['tinNum'] >= 1 || tinNum.length > 0)
									? basicDetailsError['tinNum'].message
									: null
							}
							FormHelperTextProps={{
								classes: {
									root: helperTextStyles.root,
								},
							}}
							name="tin-numberr"
							autoComplete="tin-numberr"
							value={tinNum}
							onChange={(e) => {
								if (e.target.value.length < 11) {
									let cleaned = ('' + e.target.value).replace(/\D/g, '');
									let normValue = `${cleaned.substring(0, 2)}${
										cleaned.length > 2 ? '-' : ''
									}${cleaned.substring(2, 9)}`;
									setTinNum(normValue);
								}
							}}
						/>
						<Typography
							component="div"
							variant="body1"
							sx={{
								textAlign: 'left',
								fontSize: '16px',
								mt: 0.5,
								color: '#484848',
								display: 'flex',
								mb: 2,
							}}>
							<LockIcon sx={{ mr: 1, height: '20px' }} /> Your TIN number helps us to verify your
							business, which we are required to do by law
						</Typography>
					</Grid> */}
				</Grid>
			</Box>
		</Box>
	);
};
