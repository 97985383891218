import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#5155D4',
		},
		background: {
			gray: '#E5E5E5',
			light: '#EFEEEE',
		},
		common: {
			black: '#000000',
			white: '#fff',
		},
		error: {
			main: '#F02C2C',
		},
	},

	typography: {
		fontFamily: ['GTWalsheimPro'],
		fontSize: 16,
		letterSpacing: '0.5px',
		lineHeight: '16px',
		h1: {
			fontFamily: ['GTWalsheimPro'],
			fontSize: 48,
			fontWeight: 700,
			letterSpacing: '0.5px',
			lineHeight: '48px',
		},
		h2: {
			fontFamily: ['GTWalsheimPro'],
			fontSize: 32,
			fontWeight: 700,
			letterSpacing: '0.5px',
			lineHeight: '32px',
		},
		h3: {
			fontFamily: ['GTWalsheimPro'],
			fontSize: 28,
			fontWeight: 500,
			letterSpacing: '0.5px',
			lineHeight: '32px',
		},
		h4: {
			fontFamily: ['GTWalsheimPro'],
			fontSize: 24,
			fontWeight: 700,
			letterSpacing: '0.5px',
			lineHeight: '24px',
		},
		h5: {
			fontFamily: ['GTWalsheimPro'],
			fontSize: 18,
			fontWeight: 500,
			letterSpacing: '0.5px',
			lineHeight: '24px',
		},
		body1: {
			fontFamily: ['GTWalsheimPro'],
			fontSize: 16,
			fontWeight: 400,
			letterSpacing: '0.5px',
			lineHeight: '24px',
		},
	},
});
