import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Typography, Grid, Box, Container, InputAdornment, ButtonBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { dataFetch, emailValidator } from '../../utils';
import { addMerchant } from '../../actions/merchant';
import { makeStyles } from '@material-ui/core/styles';
import { CustomAlert } from '../../components/CustomAlert';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAccount, useNetwork } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { switchNetwork } from '@wagmi/core';

const useHelperTextStyles = makeStyles(() => ({
	root: {
		marginLeft: 0,
	},
}));

export const Login = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const location = useLocation();
	console.log(state);
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [globalAlertOpen, setGlobalAlertOpen] = useState(false);
	const { address } = useAccount();
	const [hyderated, setHyderated] = useState(false);
	const { chain } = useNetwork();
	const { open } = useWeb3Modal();
	/* 	const handleredirect = async () => {
			console.log(apitoken);
			const firsttimeuser = await isFirstTimeUser(apitoken);
			if (!firsttimeuser.data.message.HasKeyPairs) {
				navigate('/merchant-onboarding', { state: apitoken });
			} else {
				navigate('/dashboard', { state: apitoken });
			}
		};	 */
	async function onOpen() {
		console.log('onOpen');
		await open();
	}

	useEffect(() => {
		if (address && hyderated) {
			console.log('address');
			console.log(address);
			fetchMerchantAndNavigate(address);
		}
	}, [address, hyderated]);

	useEffect(() => {
		if (chain && chain.id) {
			if (chain && chain.id !== 710) {
				console.log('Switch login ', chain.id);
				const network = switchNetwork({
					chainId: 710,
				});
			}
		}
	}, [chain, address]);

	useEffect(() => {
		setHyderated(true);
	}, []);

	useEffect(() => {
		toast.success(state?.toast);
		setGlobalAlertOpen(state?.message);
		if (state?.redirect?.length === 0) {
			navigate(location.pathname, { replace: true });
		}
	}, [state, navigate, location.pathname]);

	function fetchMerchantAndNavigate(address) {
		console.log('calling fetchMerchantAndNavigate');
		var url = process.env.REACT_APP_DAPP_URL + `merchant/registration-check/${address}`;
		dataFetch(url, {}, 'GET')
			.then(({ json }) => {
				console.log('json');
				console.log(json);
				let data = {
					address: address,
					merchantId: json.message.Id,
				};
				dispatch(addMerchant(data));
				if (json.message.HasOnboarded) {
					navigate('/dashboard', { state: '' });
				} else {
					navigate('/merchant-onboarding', { state: '' });
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	return (
		<Container
			component="main"
			maxWidth="xs"
			sx={{
				p: 4,
				border: '1px solid #D1D1D1',
			}}>
			{state?.message ? (
				<CustomAlert
					open={globalAlertOpen}
					close={() => setGlobalAlertOpen(false)}
					type={state.type}
					message={state.message}
				/>
			) : null}
			<Box
				sx={{
					m: 2,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
				<Typography
					component="h4"
					variant="h4"
					sx={{ textAlign: 'left', width: '100%', fontSize: '32px', mb: 5 }}>
					Sign in
				</Typography>
				<Typography
					sx={{
						fontWeight: 500,
						fontSize: '20px',
						lineHeight: '28px',
					}}
					mt={1}
					mb={2}>
					Connect your crypto wallet to log into the NumePay Business Portal.
				</Typography>
				{/* 				<Web3Button
					style={{
						backgroundColor: 'aqua',
						width: 'fit-content',
						color: 'navy'
					}}
				>
					StylingButton
				</Web3Button> */}
				<ButtonBase
					sx={{
						width: '100%',
						bgcolor: '#5155D4',
						color: '#ffffff',
						py: 2,
						borderRadius: '4px',
					}}
					//disableRipple
					onClick={() => {
						console.log('Button clicked');
						onOpen();
					}}>
					<Typography
						sx={{
							fontWeight: 500,
							fontSize: '18px',
							lineHeight: '24px',
						}}>
						Connect your wallet
					</Typography>
				</ButtonBase>
				<Typography
					sx={{
						fontWeight: 400,
						fontSize: '16px',
						lineHeight: '24px',
						textAlign: 'center',
						mt: 2, // Adds top margin of 3 units
					}}
					mt={1}
					mb={2}>
					Don’t have a wallet? No worries, but you’ll need a wallet to receive your crypto payments. Get
					started with creating one{' '}
					<Link
						href="https://metamask.io/"
						target="_blank"
						rel="noopener"
						underline="none"
						sx={{ color: 'deepblue', fontWeight: 'bold' }}>
						here
					</Link>
					.
				</Typography>
			</Box>
		</Container>
	);
};
