import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(({ theme }) => ({
	'& input': {
		color: '#595959',
		height: '8px',

		// border:'1px solid #5155D4',
		// borderRadius:'4px'
	},
	'& .MuiOutlinedInput-root': {
		fieldset: {
			border: '1px solid #DFE2E5',
		},
		'&:hover fieldset': {
			border: '1px solid #DFE2E5',
		},
		'&.Mui-focused fieldset': {
			border: `2px solid #5155D4 !important`,
		},
		'&.Mui-error fieldset': {
			border: `1px solid #F02C2C !important`,
		},
	},
}));
