import { ReactComponent as RainbowLogo } from '../assets/rainbow.svg';
import { ReactComponent as TrustLogo } from '../assets/trust.svg';

import { ReactComponent as EthSvg } from '../assets/token-icon/eth.svg';
import { ReactComponent as DaiSvg } from '../assets/token-icon/dai.svg';
import { ReactComponent as WbtcSvg } from '../assets/token-icon/wbtc.svg';
import { ReactComponent as UsdtSvg } from '../assets/token-icon/usdt.svg';
import { ReactComponent as UsdcSvg } from '../assets/token-icon/usdc.svg';
import { ReactComponent as MaticSvg } from '../assets/token-icon/matic.svg';

import SHA256 from 'crypto-js/sha256';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Hex from 'crypto-js/enc-hex';
import BN from 'bn.js';

export const tokenDecimalLen = 7;
export const usdDecimalLen = 2;

export const dataFetch = async (url, data = {}, method = 'POST', customHeader = {}) => {
	const apiConfig = {
		method: method,
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
			...customHeader,
		},
		credentials: 'same-origin',
	};
	if (method === 'POST') apiConfig.body = JSON.stringify(data);
	return fetch(url, apiConfig)
		.then(function (response) {
			// console.log('api call response',response)
			return response.json().then((json) => {
				if (json.statusCode === 401) {
					console.log('Timeout');
					window.location.href = '/';
					window.tokenexpire = true;
					localStorage.clear();
					localStorage.setItem('visited', true);
				}
				return { json, status: response.status }; //Gets cascaded to the next then block
			});
		})
		.catch(function (error) {
			throw error; //gets caught in the higher catch block
		});
};

export const getWalletIcon = (wallet) => {
	let walletLogoMap = {
		rainbow: <RainbowLogo />,
		trust: <TrustLogo />,
	};
	return walletLogoMap[wallet];
};

export const query = (params) => {
	let q = Object.keys(params)
		.map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		.join('&');
	return q;
};

export const calculatePrice = (amt, val, decimal) => {
	return ((amt / Math.pow(10, decimal)) * val.Amount).toFixed(usdDecimalLen);
};

export const totalPrice = (list, currencyState, exchangeRate, discount = {}) => {
	console.log('list', list);
	console.log('currencyState', currencyState);
	console.log('exchangeRate', exchangeRate);
	let total = 0;
	list.forEach((e) => {
		total += (e.Amount / Math.pow(10, currencyState[e.CurrencyId].Decimal)) * exchangeRate[e.CurrencyId].Amount;
		if (discount[e.CurrencyId]) {
			total +=
				(discount[e.CurrencyId] / Math.pow(10, currencyState[e.CurrencyId].Decimal)) *
				exchangeRate[e.CurrencyId].Amount;
		}
	});
	return total.toFixed(usdDecimalLen);
};

export const emailValidator = (email) => {
	let regex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(email);
};

export const passwordValidator = (password) => {
	let pass = true;
	let errors = {
		length: [false, 'Minimum 8 characters'],
		uppercase: [false, 'At least 1 capital letter (A-Z)'],
		number: [false, 'At least 1 number (0-9)'],
		special: [false, 'At least 1 special character (ex. $ # ! &)'],
	};
	if (password.length < 8) {
		errors.length[0] = true;
	}
	if (!password.match(/[A-Z]/)) {
		errors.uppercase[0] = true;
	}
	if (!password.match(/[0-9]/)) {
		errors.number[0] = true;
	}
	if (!password.match(/[^a-zA-Z0-9]/)) {
		errors.special[0] = true;
	}
	Object.values(errors).forEach((e) => (pass = pass && !e[0]));

	return { errors, pass };
};

export const getTokenLogo = (token, style = {}) => {
	if (Object.keys(style).length === 0) {
		style = { width: '24px', height: '24px', marginRight: '8px' };
	}
	switch (token) {
		case 'ETH':
			return <EthSvg style={style} />;
		case 'DAI':
			return <DaiSvg style={style} />;
		case 'WBTC':
			return <WbtcSvg style={style} />;
		case 'USDT':
			return <UsdtSvg style={style} />;
		case 'USDC':
			return <UsdcSvg style={style} />;
		case 'MATIC':
			return <MaticSvg style={style} />;
		default:
			return <></>;
	}
};
export const validURL = (str) => {
	let pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // fragment locator
	return !!pattern.test(str);
};

export const usZipCode = (str) => {
	let pattern = new RegExp('^\\d{5}(?:[-\\s]\\d{4})?$', 'i'); // fragment locator
	return !!pattern.test(str);
};

const canonicalHeaders = (reqOpts) => {
	let headers = reqOpts.headers;
	return Object.keys(headers)
		.sort(function (a, b) {
			return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
		})
		.map(function (key) {
			return key.toLowerCase() + ':' + headers[key].toString().trim().replace(/\s+/g, ' ');
		})
		.join('\n');
};

export const authHeader = (reqOpts, accessKeyId, secretAccessKey) => {
	let signedHeaders = 'content-length;content-type;host;x-amz-date;x-amz-security-token;x-amz-target';

	let credentialString = [
		reqOpts.headers['X-Amz-Date'].substr(0, 8),
		reqOpts.region,
		reqOpts.service,
		'aws4_request',
	].join('/');

	let canonicalString = [
		'POST',
		'/',
		null,
		canonicalHeaders(reqOpts) + '\n',
		signedHeaders,
		SHA256(reqOpts.body).toString(Hex),
	].join('\n');

	let stringToSig = [
		'AWS4-HMAC-SHA256',
		reqOpts.headers['X-Amz-Date'],
		credentialString,
		SHA256(canonicalString).toString(Hex),
	].join('\n');

	let signature = '';
	let kDate = HmacSHA256(reqOpts.headers['X-Amz-Date'].substr(0, 8), 'AWS4' + secretAccessKey);
	let kRegion = HmacSHA256(reqOpts.region, kDate);
	let kService = HmacSHA256(reqOpts.service, kRegion);
	let kCredentials = HmacSHA256('aws4_request', kService);
	let kSig = HmacSHA256(stringToSig, kCredentials);
	signature = kSig.toString(Hex);

	return [
		'AWS4-HMAC-SHA256 Credential=' + accessKeyId + '/' + credentialString,
		'SignedHeaders=' + signedHeaders,
		'Signature=' + signature,
	].join(', ');
};

export function convertToWei(value, decimalPlaces) {
	console.log(' value ', value);
	console.log(' decimalPlaces ', decimalPlaces);
	const parts = value.split('.');
	const wholePart = parts[0];
	const fractionalPart = parts.length > 1 ? parts[1] : '0';

	// padding the fractional part with zeros up to the specified decimal places
	const paddedFractionalPart = padRight(fractionalPart, decimalPlaces, '0');

	const wholePartInt = new BN(wholePart, 10);
	const fractionalPartInt = new BN(paddedFractionalPart, 10);

	const ten = new BN(10);
	const wholePartBaseUnits = wholePartInt.mul(ten.pow(new BN(decimalPlaces)));
	const totalBaseUnits = wholePartBaseUnits.add(fractionalPartInt);

	return totalBaseUnits;
}

function padRight(str, length, pad) {
	while (str.length < length) {
		str += pad;
	}
	return str.substring(0, length);
}
