export const setApiToken = (responseval) => {
	return {
		type: 'SET_API_TOKEN',
		payload: responseval,
	};
};

export const unsetApiToken = () => {
	return {
		type: 'UNSET_API_TOKEN',
	};
};
