import { Popover, Box, Typography } from '@mui/material';

export const Tooltip = (props) => {
	const { anchorEl, handleClose, content } = props;

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				PaperProps={{
					style: {
						backgroundColor: 'transparent',
						boxShadow: 'none',
						borderRadius: 0,
					},
				}}>
				<Box
					sx={{
						position: 'relative',
						mt: '10px',
						'&::before': {
							backgroundColor: '#fff',
							borderTop: '1px solid #5155D4',
							borderLeft: '1px solid #5155D4',
							content: '""',
							display: 'block',
							position: 'absolute',
							width: 12,
							height: 12,
							top: -6,
							transform: 'rotate(45deg)',
							left: 'calc(50% - 6px)',
						},
					}}
				/>
				<Typography
					sx={{
						p: 1,
						maxWidth: 300,
						backgroundColor: '#fff',
						border: '1px solid #5155D4',
						borderRadius: '8px',
					}}
					component="div">
					{content}
				</Typography>
			</Popover>
		</>
	);
};
