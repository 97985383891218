const initialstate = {};
const apiTokenReducer = (state = initialstate, action) => {
	switch (action.type) {
		case 'SET_API_TOKEN':
			return action.payload;
		case 'UNSET_API_TOKEN':
			return initialstate;
		default:
			return state;
	}
};
export default apiTokenReducer;
