import React from 'react';
import { Box, Typography, Grid, Radio, RadioGroup, FormControlLabel, Paper } from '@mui/material';

const Label = ({ name, desc }) => {
	return (
		<>
			<Paper
				elevation={0}
				sx={{
					marginLeft: '16px !important',
					borderRadius: '8px',
					width: 'auto',
					paddingLeft: '0px',
					paddingTop: '12px',
					paddingBottom: '8px',
				}}>
				<Grid container>
					<Grid item xs={12}>
						<Grid
							item
							sx={{
								fontSize: '16px',
								display: 'flex',
								alignItems: 'center',
								fontWeight: 500,
							}}>
							{name}
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid
							item
							sx={{
								fontSize: '16px',
								display: 'flex',
								alignItems: 'center',
								color: '#767676',
							}}>
							{desc}
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};

const businessTypes = [
	{
		name: 'Sole Proprietorship',
		desc: 'Your business is owned and operated by you alone.',
	},
	{
		name: 'Partnership',
		desc: 'Your business is owned by two or more people who share profits.',
	},
	{
		name: 'Corporation',
		desc: 'Your business is fully-independent with shareholders.',
	},
	{
		name: 'Limited Liability Company (LLC)',
		desc: 'You are a small business owner that abides by both a partnership and a corporation.',
	},
];

export const BusinessType = ({ businessType, setBusinessType }) => {
	const handleChange = (event) => {
		setBusinessType(event.target.value);
	};
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
			<Typography
				component="h3"
				variant="h3"
				style={{
					marginBottom: '10px',
					textAlign: 'left',
					width: '100%',
					fontSize: '32px',
					fontWeight: 700,
				}}>
				Select your business type
			</Typography>
			<Typography
				component="h5"
				variant="h5"
				style={{
					marginBottom: '24px',
					color: '#484848',
					textAlign: 'left',
					width: '100%',
					fontWeight: 400,
				}}>
				Choose the category that best describes your business.
			</Typography>
			<Box sx={{ mt: 1, width: '100%' }}>
				<RadioGroup
					aria-labelledby="select-token"
					name="select-token"
					value={businessType}
					onChange={handleChange}>
					<Box>
						{businessTypes.map((e, i) => {
							return (
								<FormControlLabel
									sx={{
										border: '1px solid #E8E8E8',
										mt: 1,
										mb: 1,
										borderRadius: '8px',
										width: '100%',
									}}
									key={i}
									value={e.name}
									control={<Radio />}
									label={<Label name={e.name} desc={e.desc} />}
									labelPlacement="end"
								/>
							);
						})}
					</Box>
				</RadioGroup>
			</Box>
		</Box>
	);
};
