import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
	const merchant = useSelector((state) => state.merchant);
	const [loading, setLoading] = useState(true);
	useEffect(() => setLoading(false), []);
	if (loading) return null;
	return Object.keys(merchant).length > 0 ? children : <Navigate to="/" replace />;
};

export default ProtectedRoute;
