import { combineReducers } from 'redux';
import { merchantReducer } from './merchant';
import { currencyReducer } from './currencies';
import apiTokenReducer from './apiTokenReducer';
import cognitoTokenReducer from './cognitoTokenReducer';
import firstTimeUserReducer from './firstTimeUserReducer';
import withdrawalReducer from './withdrawalReducer';
export default combineReducers({
	merchant: merchantReducer,
	currency: currencyReducer,
	apiTokenReducer,
	cognitoTokenReducer,
	firstTimeUserReducer,
	withdrawal: withdrawalReducer,
});
