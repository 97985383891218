import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Typography, ButtonBase } from '@mui/material';
import { useSelector } from 'react-redux';
import Logo from '../../assets/nume-business-logo.png';
import { phone } from 'phone';
import { BasicDetails } from './BasicDetails';
import { AddressDetails } from './AddressDetails';
import { BusinessType } from './BusinessType';
import { useNavigate } from 'react-router-dom';
import { dataFetch, validURL, usZipCode } from '../../utils';
import { Loader } from '../../components/Loader';
import { useDispatch } from 'react-redux';
import { addMerchant } from '../../actions/merchant';
import { unsetApiToken } from '../../actions/apiTokenActions';
import { CustomButton } from '../../components/CustomButton';
import { toast } from 'react-toastify';
import { emailValidator } from '../../utils';
import { useAccount, useDisconnect } from 'wagmi';
import { ReactComponent as DropDown } from '../../assets/drop-down.svg';
import { ReactComponent as Disconnect } from '../../assets/disconnect.svg';
const steps = ['Basic details', 'Address', 'business type'];

const validateBasicDetails = ({ businessName, phoneNumber, website, tinNum }) => {
	let errorList = [];
	if (businessName.length === 0) {
		let e = {
			type: 'businessName',
			message: 'Business name is required',
		};
		errorList.push(e);
	}
	if (phoneNumber.length === 0) {
		let e = {
			type: 'phoneNumber',
			message: 'Phone number is required',
		};
		errorList.push(e);
	} else if (!phone(phoneNumber).isValid) {
		let e = {
			type: 'phoneNumber',
			message: 'Phone number is invalid',
		};
		errorList.push(e);
	}
	if (website.length === 0) {
		let e = {
			type: 'website',
			message: 'Website is required',
		};
		errorList.push(e);
	} else if (!validURL(website)) {
		let e = {
			type: 'website',
			message: 'Website is invalid',
		};
		errorList.push(e);
	}
	/* 	if (tinNum.length === 0) {
		let e = {
			type: 'tinNum',
			message: 'TIN number is required',
		};
		errorList.push(e); 
	} else if (tinNum.length !== 10) {
		let e = {
			type: 'tinNum',
			message: 'TIN number is invalid',
		};
		errorList.push(e);
	}*/
	return errorList;
};

const validateAddressDetails = ({ streetAddress, zipCode, city, country, region }) => {
	let errorList = [];
	if (streetAddress.length === 0) {
		let e = {
			type: 'streetAddress',
			message: 'Street address is required',
		};
		errorList.push(e);
	}
	if (city.length === 0) {
		let e = {
			type: 'city',
			message: 'City is required',
		};
		errorList.push(e);
	}
	if (zipCode.length === 0) {
		let e = {
			type: 'zipCode',
			message: 'Zip code is required',
		};
		errorList.push(e);
	} else if (!usZipCode(zipCode)) {
		let e = {
			type: 'zipCode',
			message: 'Zip code is invalid',
		};
		errorList.push(e);
	}
	if (region.length === 0) {
		let e = {
			type: 'region',
			message: 'State is required',
		};
		errorList.push(e);
	}
	if (country.length === 0) {
		let e = {
			type: 'country',
			message: 'Country is required',
		};
		errorList.push(e);
	}

	let banList = ['losangeles', 'washington', 'ohio', 'newyork', 'louisiana'];
	let pregion = region.replace(/\s+/g, '').toLowerCase();
	if (banList.includes(pregion)) {
		let e = {
			type: 'region',
			message: 'We do not operate here',
		};
		errorList.push(e);
	}
	return errorList;
};

const validateSupportedCurrencies = (currencyState, coins) => {
	let errorList = [];
	let selectedCoins = Object.keys(coins).filter((coin) => coins[coin]);
	if (selectedCoins.length === 0) {
		errorList.push('coins');
	} else {
		Object.keys(coins).forEach((c) => {
			if (coins[c] && !currencyState[c]) {
				errorList.push(c + 'coin does not exist');
			}
		});
	}
	return errorList;
};

function useOutsideAlerter(ref1, ref2, callBack) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (
				ref1.current &&
				!ref1.current.contains(event.target) &&
				ref2.current &&
				!ref2.current.contains(event.target)
			) {
				callBack();
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref1, ref2]);
}

export const Details = () => {
	const [businessName, setBusinessName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [website, setWebsite] = useState('');
	const [tinNum, setTinNum] = useState('');
	const [email, setEmail] = useState('');

	const [streetAddress, setStreetAddress] = useState('');
	const [appartment, setAppartment] = useState('');
	const [city, setCity] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [region, setRegion] = useState('');
	const [country, setCountry] = useState('US');
	const [isLoading, setIsLoading] = useState(false);
	const [keysGenerated, setKeysGenerated] = useState(false);
	const [showDisconnect, setShowDisconnect] = useState(false);
	const { address } = useAccount();
	const { disconnect } = useDisconnect({
		onSuccess(data) {
			console.log('Disconnected');
			dispatch(addMerchant({}));
			dispatch(unsetApiToken());
			navigate('/');
		},
	});
	const [focusCounter, setFocusCounter] = useState({
		businessName: 0,
		phoneNumber: 0,
		website: 0,
		industry: 0,
		streetAddress: 0,
		appartment: 0,
		city: 0,
		zipCode: 0,
		region: 0,
		country: 0,
	});

	const [focusTracker, setFocusTracker] = useState({
		businessName: false,
		phoneNumber: false,
		website: false,
		industry: false,
		streetAddress: false,
		appartment: false,
		city: false,
		zipCode: false,
		region: false,
		country: false,
	});

	const [basicDetailsError, setBasicDetailsError] = useState({
		businessName: false,
	});
	const [addressError, setAddressError] = useState({
		streetAddress: false,
	});
	const [activeStep, setActiveStep] = useState(0);
	const currencyState = useSelector((state) => state.currency);
	const [coins, setCoins] = useState({});
	const [currencyNotFilled, setCurrencyNotFilled] = useState(false);
	const merchant = useSelector((state) => state.merchant);
	const [businessType, setBusinessType] = useState('Sole Proprietorship');
	const [nextLoading, setNextLoading] = useState(false);
	const dispatch = useDispatch();
	const submitBusiness = async () => {
		let twebsite = website;
		if (!/^https?:\/\//i.test(twebsite)) {
			twebsite = 'https://' + twebsite;
		}
		let data = {
			businessName,
			phoneNumber: '+1' + phoneNumber.replaceAll('-', ''),
			website: twebsite,
			tin: tinNum,
			email: email,
		};
		let tc = {};
		tc[Object.keys(currencyState)[0]] = true;
		setCoins(tc);
		var url = process.env.REACT_APP_DAPP_URL + 'merchant/add-business-info';
		dataFetch(url, data, 'POST', merchant)
			.then(({ json, status }) => {
				console.log(json, status);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const submitAddress = async () => {
		let data = {
			streetAddress,
			streetAddressSecond: appartment,
			city,
			zipCode,
			state: region,
			country,
		};
		let tc = {};
		tc[Object.keys(currencyState)[0]] = true;
		setCoins(tc);
		try {
			var url = process.env.REACT_APP_DAPP_URL + 'merchant/add-business-address';
			let res = await dataFetch(url, data, 'POST', merchant);
			console.log(res.json.statusCode, res.json.statusCode !== 200);
			if (res.json.statusCode !== 200) {
				setNextLoading(false);
				toast.error(res.json.message);
				return true;
			}
		} catch (error) {
			setNextLoading(false);
			console.log(error);
			toast.error(error);
			return true;
		}
		setNextLoading(false);
		return false;
	};

	const submitSupportedCoins = async () => {
		let data = {
			currencies: [],
		};
		Object.entries(coins).forEach(([key, value]) => {
			if (value) data.currencies.push(parseInt(key));
		});
		if (data.currencies.length === 0) {
			console.log('no coins selected');
		}
		var url = process.env.REACT_APP_DAPP_URL + 'merchant/add-supported-currencies';
		dataFetch(url, data, 'POST', merchant)
			.then(({ json, status }) => {
				console.log(json, status);
				navigate('/dashboard');
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const submitBusinessType = async () => {
		let data = {
			businessType,
		};
		var url = process.env.REACT_APP_DAPP_URL + 'merchant/add-business-type';
		dataFetch(url, data, 'POST', merchant)
			.then(({ json, status }) => {
				console.log(json, status);
				navigate('/dashboard');
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleNext = async () => {
		let bErrorKeys = [];
		let cErrorKeys = [];
		let aErrorKeys = [];
		let submitError = false;
		switch (activeStep) {
			case 1:
				bErrorKeys = validateBasicDetails({
					businessName,
					phoneNumber,
					website,
					tinNum,
				});
				if (bErrorKeys.length === 0) submitBusiness();
				break;
			case 0:
				aErrorKeys = validateAddressDetails({
					streetAddress,
					appartment,
					city,
					zipCode,
					region,
					country,
				});
				if (aErrorKeys.length === 0) {
					setNextLoading(true);
					submitError = await submitAddress();
				}
				break;
			case 2:
				submitBusinessType();
				break;
			/* 			case 3:
				cErrorKeys = validateSupportedCurrencies(currencyState, coins);
				if (cErrorKeys.length === 0) submitSupportedCoins();
				break; */
			default:
				break;
		}
		if (bErrorKeys.length > 0) {
			let tempBasicDetailsError = {};
			bErrorKeys.forEach((e) => {
				tempBasicDetailsError[e.type] = { error: true, message: e.message };
			});
			console.log(tempBasicDetailsError);
			setFocusCounter({
				businessName: 1,
				phoneNumber: 1,
				website: 1,
				tinNum: 1,
			});
			setBasicDetailsError(tempBasicDetailsError);
		} else if (aErrorKeys.length > 0) {
			let tempAddressError = {};
			aErrorKeys.forEach((e) => {
				tempAddressError[e.type] = { error: true, message: e.message };
			});
			console.log(tempAddressError);
			setAddressError(tempAddressError);
			setFocusCounter({
				streetAddress: 1,
				appartment: 1,
				city: 1,
				zipCode: 1,
				region: 1,
			});
		} else if (cErrorKeys.length > 0) {
			setCurrencyNotFilled(true);
		} else if (submitError) {
			console.log(submitError);
		} else {
			if (activeStep < steps.length - 1) setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		if (activeStep === 0 || (activeStep === 2 && keysGenerated)) {
			disconnect();
			//await Auth.signOut();
			return;
		}
		let tempBasicDetailsError = { name: false };
		setBasicDetailsError(tempBasicDetailsError);
		setAddressError({ streetAddress: false });
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	/* 	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			var url = process.env.REACT_APP_DAPP_URL + `merchant/registration-check/${merchant.address}`
			let data = await dataFetch(url, {}, 'GET', merchant);
			if (data.status === 200) {
				if (data.json.message.Status === 'RECORD_NOT_FOUND') {
					dispatch(addMerchant({}));
					dispatch(unsetApiToken());
					navigate('/');
				}
				if (data.json.message.HasOnboarded) {
					navigate('/dashboard');
				}
			}
			setIsLoading(false);
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [merchant]); */

	useEffect(() => {
		let bErrorKeys = validateBasicDetails({
			businessName,
			phoneNumber,
			website,
			tinNum,
		});
		let tempBasicDetailsError = {};
		bErrorKeys.forEach((e) => {
			tempBasicDetailsError[e.type] = { error: true, message: e.message };
		});
		setBasicDetailsError(tempBasicDetailsError);
	}, [businessName, phoneNumber, website, tinNum]);

	useEffect(() => {
		let aErrorKeys = validateAddressDetails({
			streetAddress,
			appartment,
			city,
			zipCode,
			region,
			country,
		});
		let tempAddressError = {};
		aErrorKeys.forEach((e) => {
			tempAddressError[e.type] = { error: true, message: e.message };
		});
		setAddressError(tempAddressError);
	}, [streetAddress, appartment, city, zipCode, region, country]);

	const handleReset = () => {
		setActiveStep(0);
	};
	const navigate = useNavigate();
	const getStepperContent = (step) => {
		switch (step) {
			case 1:
				return (
					<>
						<BasicDetails
							basicDetailsError={basicDetailsError}
							businessName={businessName}
							setBusinessName={setBusinessName}
							phoneNumber={phoneNumber}
							setPhoneNumber={setPhoneNumber}
							website={website}
							setWebsite={setWebsite}
							tinNum={tinNum}
							setTinNum={setTinNum}
							email={email}
							setEmail={setEmail}
							streetAddress={streetAddress}
							setStreetAddress={setStreetAddress}
							appartment={appartment}
							setAppartment={setAppartment}
							city={city}
							setCity={setCity}
							zipCode={zipCode}
							setZipCode={setZipCode}
							region={region}
							setRegion={setRegion}
							country={country}
							setCountry={setCountry}
							focusCounter={focusCounter}
							setFocusCounter={setFocusCounter}
							focusTracker={focusTracker}
							setFocusTracker={setFocusTracker}
						/>
					</>
				);
			case 0:
				return (
					<>
						<AddressDetails
							addressError={addressError}
							streetAddress={streetAddress}
							setStreetAddress={setStreetAddress}
							appartment={appartment}
							setAppartment={setAppartment}
							city={city}
							setCity={setCity}
							zipCode={zipCode}
							setZipCode={setZipCode}
							region={region}
							setRegion={setRegion}
							country={country}
							setCountry={setCountry}
							focusCounter={focusCounter}
							setFocusCounter={setFocusCounter}
							focusTracker={focusTracker}
							setFocusTracker={setFocusTracker}
						/>
					</>
				);
			case 2:
				return (
					<>
						<BusinessType setBusinessType={setBusinessType} businessType={businessType} />
					</>
				);
			/* 			case 3:
				return (
					<>
						<ChooseCoins
							setCoins={setCoins}
							coins={coins}
							currencyNotFilled={currencyNotFilled}
							setCurrencyNotFilled={setCurrencyNotFilled}
						/>
					</>
				); */
			default:
				return <>default</>;
		}
	};

	const activeStepBtnName = () => {
		switch (activeStep) {
			case steps.length - 1:
				return 'Go to dashboard';
			default:
				return 'Next';
		}
	};

	const handleLogout = async () => {
		disconnect();
		//await Auth.signOut();
		navigate('/');
	};

	const ref1 = useRef(null);
	const ref2 = useRef(null);
	useOutsideAlerter(ref1, ref2, () => {
		setShowDisconnect(false);
	});

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}>
				<img
					src={Logo}
					alt="logo"
					style={{
						margin: '20px 40px',
						maxWidth: '200px',
					}}
				/>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						justifyContent: 'center',
					}}
					mr={5}>
					{address ? (
						<ButtonBase
							sx={{
								boxShadow: '0 4px 4px rgb(0 0 0 / 0.2)',
								borderRadius: '10px',
								py: '8px',
								px: '16px',
								bgcolor: '#FFFFFF',
							}}
							onClick={() => {
								setShowDisconnect(!showDisconnect);
							}}
							ref={ref1}>
							<Typography
								sx={{
									fontWeight: 700,
									fontSize: '18px',
									lineHeight: '24px',
								}}
								color="#000000">
								{`${address.slice(0, 4)}...${address.slice(-4)}`}
							</Typography>
							<DropDown style={{ marginLeft: 32 }} />
						</ButtonBase>
					) : null}

					<Box
						sx={{
							position: 'absolute',
							visibility: showDisconnect ? 'visible' : 'hidden',
						}}
						mt={11}>
						<ButtonBase
							sx={{
								boxShadow: '0 4px 4px rgb(0 0 0 / 0.1)',
								borderRadius: '4px',
								py: '12px',
								px: '16px',
								pr: '56px',
								bgcolor: '#FFFFFF',
							}}
							onClick={() => {
								setShowDisconnect(false);
								handleLogout();
							}}
							ref={ref2}>
							<Disconnect />
							<Typography
								sx={{
									fontWeight: 400,
									fontSize: '16px',
									lineHeight: '16px',
								}}
								color="rgba(16, 21, 33, 0.6)"
								ml={1}>
								Disconnect
							</Typography>
						</ButtonBase>
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					width: '750px',
					margin: 'auto',
					padding: '20px',
					paddingTop: 0,
					paddingBottom: 0,
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<Box
					sx={{
						border: '1px solid #E8E8E8',
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
						p: 4,
					}}>
					{activeStep === steps.length ? (
						<>
							<Typography sx={{ mt: 2, mb: 1 }}>Finished</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button
									color="primary"
									variant="contained"
									style={{
										fontWeight: 500,
										width: '120px',
										textTransform: 'none',
									}}
									onClick={handleReset}>
									Reset
								</Button>
							</Box>
						</>
					) : (
						<>
							<Typography sx={{ mt: 2, mb: 1 }} component="div">
								{getStepperContent(activeStep)}
							</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Button
									color="primary"
									variant="outlined"
									style={{
										fontWeight: 500,
										width: '200px',
										textTransform: 'none',
									}}
									onClick={handleBack}
									sx={{ mr: 1 }}>
									{activeStep === 0 || (activeStep === 2 && keysGenerated)
										? 'Back to Sign In'
										: 'Back'}
								</Button>
								<Box sx={{ flex: '1 1 auto' }} />
								<CustomButton
									color="primary"
									variant="contained"
									style={{
										fontWeight: 500,
										width: '200px',
										textTransform: 'none',
										backgroundColor: 'auto',
										opacity: 1,
										color: 'auto',
									}}
									isLoading={nextLoading}
									onClick={handleNext}>
									{activeStepBtnName()}
								</CustomButton>
							</Box>
						</>
					)}
				</Box>
			</Box>
		</>
	);
};
