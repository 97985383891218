import React, { useState } from 'react';
import { Typography, Button, Container, Box, InputAdornment } from '@mui/material';
import { emailValidator } from '../../utils';
import { StyledTextField } from '../../components/StyledTextField';
import { makeStyles } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Logo from '../../assets/nume-business-logo.png';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../components/CustomButton';
import LinkSent from '../../assets/link-sent.png';
const useHelperTextStyles = makeStyles(() => ({
	root: {
		marginLeft: '0px !important',
		marginRight: '0px !important',
		marginTop: '0px !important',
		paddingTop: '4px',
	},
}));

export const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [emailFocus, setEmailFocus] = useState(false);
	const [focusCounter, setFocusCounter] = useState({
		email: 0,
		password: 0,
	});
	const helperTextStyles = useHelperTextStyles();
	const emailError = () => focusCounter.email > 0 && !emailValidator(email) && !emailFocus;
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [sent, setSent] = useState(false);
	const handleForgotPassword = async () => {
		try {
			setIsLoading(true);
			if (!emailValidator(email)) {
				setFocusCounter({ ...focusCounter, email: 1 });
				setIsLoading(false);
				return;
			}
			//await Auth.forgotPassword(email);
			setSent(true);
			console.log('Forgot password email resent');
			setIsLoading(false);
		} catch (err) {
			setSent(true);
			setIsLoading(false);
			console.log('Error in sending forgot password email');
		}
	};

	return (
		<div>
			<img
				src={Logo}
				alt="logo"
				style={{
					margin: '20px 40px',
					position: 'absolute',
					maxWidth: '200px',
				}}
			/>
			<Container
				style={{
					minHeight: '100vh',
					minWidth: '100vw',
					display: 'flex',
				}}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						margin: 'auto',
					}}>
					<Box
						sx={{
							// height: '250px',
							width: '600px',
							padding: '20px',
							border: '1px solid #E8E8E8',
							borderRadius: '4px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							p: 4,
							boxShadow: ' 0px 4px 12px rgba(0, 0, 0, 0.08);',
							// alignItems: 'center',
						}}>
						{sent ? (
							<>
								<div style={{ textAlign: 'center' }}>
									<Typography component="h1" variant="h4" sx={{ pb: 2, fontSize: '28px' }}>
										Thank you!
									</Typography>
									<Typography variant="body1">
										An email with a link to reset password will be sent to {email} if it is
										associated with a NumePay Business account.
									</Typography>
								</div>
								<img
									src={LinkSent}
									alt="LinkSent"
									style={{ width: '240px', margin: 'auto', padding: '20px' }}
								/>
								<Typography variant="body1" sx={{ textAlign: 'center' }}>
									If you haven’t received the email, also check the spam folder.
								</Typography>
							</>
						) : (
							<div>
								<div>
									<Typography component="h1" variant="h4" sx={{ pb: 2, fontSize: '28px' }}>
										Forgot password?
									</Typography>
									<Typography variant="body1">
										Enter email address associated with your Nume account to receive a reset your
										password link.
									</Typography>
								</div>

								<StyledTextField
									onChange={(e) => setEmail(e.target.value)}
									onBlur={() => {
										setEmailFocus(false);
										setFocusCounter({ ...focusCounter, email: 1 });
									}}
									onFocus={() => setEmailFocus(true)}
									margin="normal"
									fullWidth
									sx={{ background: '#fff', pt: 2 }}
									id="email"
									placeholder="Email"
									name="email"
									autoComplete="email"
									error={emailError()}
									helperText={emailError() ? 'Please enter a valid email' : null}
									FormHelperTextProps={{
										classes: {
											root: helperTextStyles.root,
										},
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												{emailError() ? <CancelOutlinedIcon sx={{ color: 'red' }} /> : <></>}
											</InputAdornment>
										),
									}}
								/>
								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button
										style={{
											fontWeight: 500,
											height: '45px',
											textTransform: 'none',
											margin: '20px 0',
											width: '200px',
										}}
										onClick={() => {
											navigate('/');
										}}
										variant="outlined">
										Back to sign in
									</Button>
									<CustomButton
										sx={{
											fontWeight: 500,
											height: '45px',
											textTransform: 'none',
											margin: '20px 0',
											width: '200px',
										}}
										isLoading={isLoading}
										onClick={handleForgotPassword}
										variant="contained">
										Send reset link
									</CustomButton>
								</Box>
							</div>
						)}
					</Box>
				</Box>
			</Container>
		</div>
	);
};
