import React from 'react';
import { Button } from '@mui/material';
import { CircularProgress } from '@mui/material';

export const CustomButton = ({ sx, variant, size, disabled, color, children, isLoading, onClick }) => {
	return (
		<Button
			sx={{
				...sx,
				fontWeight: 500,
				textTransform: 'none',
				background: isLoading ? 'rgba(81, 85, 212, 0.7) !important' : 'auto',
				color: isLoading ? '#fff !important' : 'auto',
			}}
			variant={variant}
			size={size}
			disabled={disabled || isLoading}
			color={color}
			onClick={onClick}>
			{children} {isLoading ? <CircularProgress style={{ marginLeft: '4px' }} color="inherit" size={16} /> : ''}
		</Button>
	);
};
