import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

import { ThirdwebProvider, metamaskWallet, localWallet } from '@thirdweb-dev/react';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { polygonMumbai, polygon } from 'wagmi/chains';
import { alchemyProvider } from '@wagmi/core/providers/alchemy';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';

import { Navbar } from './components/Navbar/';
import { NotFound } from './pages/NotFound';
import { Home } from './pages/Home';
import { VerifyEmail } from './pages/VerifyEmail';
import { theme } from './theme';
import { Details } from './pages/MerchantDetail';
//import { SalesOverview } from './pages/SalesOverview';
//import { Dashboard } from './pages/Dashboard';
import { WithdrawFund } from './pages/WithdrawFund';
import WithdrawAvailable from './pages/WithdrawFund/WithdrawAvailable';
import WithdrawConfirmation from './pages/WithdrawFund/WithdrawConfirmation';

//import { Settings } from './pages/Settings';
import { ResetPassword } from './pages/ResetPassword';
import { ForgotPassword } from './pages/ResetPassword';
import { ChangePassword } from './pages/ResetPassword';

import { Loader } from './components/Loader';

import { EmailVerified } from './pages/VerifyEmail';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addCurrency } from './actions/currencies';
import { dataFetch } from './utils';

import { setCognitoToken, unsetCognitoToken } from './actions/cognitoTokenActions';
import { setfirsttimeuser, unsetfirsttimeuser } from './actions/firstTimeUserActions';
import { unsetApiToken } from './actions/apiTokenActions';
import { Amplify, Auth, Hub } from 'aws-amplify';

import ProtectedRoute from './ProtectedRoute';

const SalesOverviewComponent = lazy(() => import('./pages/SalesOverview/SalesOverview'));
const DashboardComponent = lazy(() => import('./pages/Dashboard/Dashboard'));
const SettingsComponent = lazy(() => import('./pages/Settings/Settings'));
const renderLoader = () => {
	return <Loader />;
};

const SalesOverview = () => (
	<Suspense fallback={renderLoader()}>
		<SalesOverviewComponent />
	</Suspense>
);
const Dashboard = () => (
	<Suspense fallback={renderLoader()}>
		<DashboardComponent />
	</Suspense>
);
const Settings = () => (
	<Suspense fallback={renderLoader()}>
		<SettingsComponent />
	</Suspense>
);

const nume = {
	id: 710,
	name: 'Nume testnet',
	network: 'nume',
	nativeCurrency: {
		decimals: 18,
		name: 'DAI',
		symbol: 'DAI',
	},
	rpcUrls: {
		default: { http: ['https://rpc.numecrypto.com/'] },
	},
	blockExplorers: {
		default: { name: 'SnowTrace', url: 'https://dev.explorer.numecrypto.com/' },
	},
	testnet: true,
};

const projectId = '0db905236551260ddb6c29c2e096eefe';
//const projectId = "d234a25626cfc8953970420992a1927e";
const chains = [polygonMumbai, nume, polygon];

const { provider } = configureChains(chains, [
	alchemyProvider({
		apiKey: 'https://polygon-mumbai.g.alchemy.com/v2/ATUqj90wk8dyuWc5uNADwpqGjtJi4YS3',
	}),
	jsonRpcProvider({
		rpc: (chain) => ({ http: chain.rpcUrls.default.http[0] }),
	}),
	w3mProvider({ projectId }),
]);
const wagmiClient = createClient({
	autoConnect: true,
	connectors: [
		...w3mConnectors({ version: 1, chains, projectId }),
		new CoinbaseWalletConnector({
			chains,
		}),
	],
	provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

export const App = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dataFetch(process.env.REACT_APP_DAPP_URL + 'get-currencies', {}, 'GET')
			.then(({ json, status }) => {
				let result = {};
				for (let item of json.message.Currencies) {
					result[item.ID] = item;
				}
				dispatch(addCurrency(result));
			})
			.catch((error) => {
				console.log(error);
			});
		/* eslint-disable */
	}, []);

	/* 	const checkUser = async () => {
		try {
			const getUserId = await Auth.currentUserPoolUser();
			const credentials = await Auth.currentUserCredentials();
			let data = {
				username: getUserId.username,
				email: getUserId.attributes.email,
				sub: getUserId.attributes.sub,
				region: process.env.REACT_APP_REGION,
				userPoolId: getUserId.pool.userPoolId,
				accessKeyId: credentials.accessKeyId,
				secretAccessKey: credentials.secretAccessKey,
				sessionToken: credentials.sessionToken,
				identityPoolId: credentials.identityId,
			};
			dispatch(setCognitoToken(data));
		} catch (e) {
			console.log(e);
		}
	}; */

	useEffect(() => {
		const listener = (data) => {
			/* 			if (data.payload.event === 'signIn') {
				checkUser();
			}
			if (data.payload.event === 'signOut') {
				dispatch(unsetApiToken());
				dispatch(unsetCognitoToken());
				dispatch(unsetfirsttimeuser());
			} */
		};
		Hub.listen('auth', listener);
		return () => {
			Hub.remove('auth', listener);
		};
	}, []);

	return (
		<>
			<WagmiConfig client={wagmiClient}>
				<ThirdwebProvider activeChain="ethereum" supportedWallets={[metamaskWallet(), localWallet()]}>
					<ThemeProvider theme={theme}>
						<ToastContainer position="bottom-center" theme="colored" autoClose={1500} />
						<Router>
							<Routes>
								<Route exact path="/" element={<Home />} />
								<Route exact path="/verify-email" element={<VerifyEmail />} />
								<Route exact path="/forgot-password" element={<ForgotPassword />} />
								<Route exact path="/reset-password" element={<ResetPassword />} />
								<Route exact path="/email-verified" element={<EmailVerified />} />
								<Route
									exact
									path="/merchant-onboarding"
									element={
										<ProtectedRoute>
											<Details />
										</ProtectedRoute>
									}
								/>
								<Route
									exact
									path="/change-password"
									element={
										<ProtectedRoute>
											<ChangePassword />
										</ProtectedRoute>
									}
								/>
								<Route
									exact
									path="/sales-overview"
									element={
										<ProtectedRoute>
											<Navbar>
												<SalesOverview />
											</Navbar>
										</ProtectedRoute>
									}
								/>
								<Route
									exact
									path="/sales-overview/:num"
									element={
										<ProtectedRoute>
											<Navbar>
												<SalesOverview />
											</Navbar>
										</ProtectedRoute>
									}
								/>
								<Route
									exact
									path="/dashboard"
									element={
										<ProtectedRoute>
											<Navbar>
												<Dashboard />
											</Navbar>
										</ProtectedRoute>
									}
								/>
								<Route
									exact
									path="/withdraw-funds"
									element={
										<ProtectedRoute>
											<Navbar>
												<WithdrawFund />
											</Navbar>
										</ProtectedRoute>
									}
								/>

								<Route
									exact
									path="/withdraw-available"
									element={
										<ProtectedRoute>
											<Navbar>
												<WithdrawAvailable />
											</Navbar>
										</ProtectedRoute>
									}
								/>

								<Route
									exact
									path="/withdraw-confirmation"
									element={
										<ProtectedRoute>
											<Navbar>
												<WithdrawConfirmation />
											</Navbar>
										</ProtectedRoute>
									}
								/>

								<Route
									exact
									path="/settings"
									element={
										<ProtectedRoute>
											<Navbar>
												<Settings />
											</Navbar>
										</ProtectedRoute>
									}
								/>
								<Route path="*" element={<NotFound />} />
							</Routes>
						</Router>
					</ThemeProvider>
				</ThirdwebProvider>
			</WagmiConfig>
			<Web3Modal
				projectId={projectId}
				ethereumClient={ethereumClient}
				themeVariables={{
					'--w3m-accent-color': '#5155D4',
					'--w3m-background-color': '#5155D4',
				}}
				walletImages={{
					//coinbaseWallet: '/static/images/coinbase.png',
					coinbaseWallet:
						'https://images.ctfassets.net/c5bd0wqjc7v0/3dFdY6GvgLgCIXmBiN6eiA/d4acc5d4c5d557566cf0e46f9b58de43/icon-buy-and-sell.svg',
				}}
			/>
		</>
	);
};
