import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Logo from '../../assets/nume-business-logo.png';
import LandingSide from '../../assets/landingside.png';
import { Login } from './Login';
import { useLocation } from 'react-router-dom';
import { ReactComponent as YesIcon } from '../../assets/yes.svg';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const Home = () => {
	const ref = useRef();
	const { state } = useLocation();
	const navigate = useNavigate();
	const apitoken = useSelector((state) => state.apiTokenReducer);
	const [showSignup, setShowSignup] = useState(
		!state?.showLogin && (localStorage.getItem('visited') !== 'true' || state?.showSignup)
	);
	/* 	useEffect(() => {
		localStorage.setItem('visited', true);
		const handleredirect = async () => {
			const firsttimeuser = await isFirstTimeUser(apitoken);
			console.log(Object.keys(apitoken).length);
			if (Object.keys(apitoken).length > 0) {
				if (!firsttimeuser.data.message.HasOnboarded) {
					navigate('/merchant-onboarding');
				} else {
					navigate('/dashboard');
				}
			}
		};

		handleredirect();
	}, [apitoken, navigate]); */
	return (
		<Grid container style={{ height: '100vh' }}>
			<img src={Logo} alt="logo" style={{ margin: '20px 40px', height: '56px', position: 'absolute' }} />
			<Grid item sm={6} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Box
					sx={{
						p: 4,
					}}>
					<Typography
						variant="h1"
						sx={{
							fontWeight: 'bold',
							fontSize: '32px',
							margin: '20px 40px',
							color: 'text.primary',
						}}>
						<div style={{ display: 'flex' }}>
							<YesIcon style={{ paddingTop: '12px', marginRight: '8px', boxSizing: 'initial' }} />{' '}
							<div>Accept crypto payments with no gas fees.</div>
						</div>
					</Typography>
					<Typography
						variant="h1"
						sx={{
							fontWeight: 'bold',
							fontSize: '32px',
							margin: '20px 40px',
							color: 'text.primary',
						}}>
						<div style={{ display: 'flex' }}>
							<YesIcon style={{ paddingTop: '12px', marginRight: '8px', boxSizing: 'initial' }} />{' '}
							<div>Instant payment confirmations.</div>
						</div>
					</Typography>
					<Typography
						variant="h1"
						sx={{
							fontWeight: 'bold',
							fontSize: '32px',
							margin: '20px 40px',
							color: 'text.primary',
						}}>
						<div style={{ display: 'flex' }}>
							<YesIcon style={{ paddingTop: '12px', marginRight: '8px', boxSizing: 'initial' }} />{' '}
							<div>Accept USDT, USDC, DAI, MATIC, ETH, WBTC.</div>
						</div>
					</Typography>
					<Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
						<img
							style={{
								width: '50%',
								maxWidth: '440px',
								margin: 'auto',
							}}
							src={LandingSide}
							alt="coin-tower"
						/>
					</Box>
				</Box>
			</Grid>
			<Grid
				item
				sm={6}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					minHeight: '100vh',
				}}>
				{<Login setShowSignup={setShowSignup} />}
				{false ? (
					<Flippy ref={ref} isFlipped={!showSignup} flipOnClick={false}>
						<BackSide>
							<Login setShowSignup={setShowSignup} />
						</BackSide>
					</Flippy>
				) : null}
			</Grid>
		</Grid>
	);
};
