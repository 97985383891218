import React, { useEffect } from 'react';
import { Alert } from '@mui/material';

export const CustomAlert = ({ open, close, type, message }) => {
	useEffect(() => {
		// const interval = setInterval(() => {
		// 	close();
		// }, 2000);
		// return () => {
		// 	clearInterval(interval);
		// };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (open) {
		return (
			<Alert severity={type} onClose={close ? () => close() : null}>
				{message}
			</Alert>
		);
	}
	return <></>;
};
