import { Box, TableContainer, Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { usdDecimalLen, calculatePrice, getTokenLogo, tokenDecimalLen, convertToWei } from '../../utils';
import { ReactComponent as ProcessingIcon } from '../../assets/Processing.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { Tooltip } from './Tooltip';
import './previouswithdrawal.css';

const headercss = {
	fontSize: '14px',
	lineHeight: '16px',
	fontWeight: 700,
	textAlign: 'left',
	padding: '6px',
};

const DefinitionsWrap = ({ content }) => {
	return (
		<div
			style={{
				maxWidth: '300px',
				padding: '16px',
				paddingTop: 0,
				fontSize: '14px',
			}}>
			{content}
		</div>
	);
};

const TableHeader = () => {
	const headers = [
		{ name: 'Date', width: '10%' },
		{ name: 'Tokens withdrawn', width: '20%' },
		{ name: 'Withdrawn amount', width: '20%' },
		{ name: 'Nume fee', width: '15%' },
		{ name: 'Status', width: '15%' },
		{ name: '', width: '15%' },
	];
	return (
		<TableHead>
			<TableRow sx={{ backgroundColor: '#F8F8FF' }}>
				{headers.map((header) => (
					<TableCell key={header.name} width={header.width}>
						<Typography sx={headercss}>{header.name}</Typography>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const WithdrawalStatusDefinition = () => {
	return (
		<Typography component="div" style={{ maxWidth: '300px', padding: '16px', paddingTop: 0 }}>
			<Typography
				style={{
					marginTop: '8px',
					fontSize: '14px',
				}}>
				<span style={{ fontWeight: 500 }}>Processing</span> - Your withdrawal request has been submitted and is
				being processed by Nume. Withdrawals can take 24-48 hours to complete.
			</Typography>
			<Typography
				style={{
					marginTop: '8px',
					fontSize: '14px',
				}}>
				<span style={{ fontWeight: 500 }}>Complete</span> - Your withdrawal request has been processed
				successfully and funds have been settled to the Polygon PoS chain.
			</Typography>
		</Typography>
	);
};

const definitions = {
	nf: 'Nume charges a flat 0.10 DAI ($0.10) on each withdrawal.',
	wa: 'Final amount you have withdrawn. The USD value you receive could be different from what you see here given volatility in the market price of your tokens.',
};

function PreviousWithdrawals({ exchangeRate, withdrawalLog }) {
	const [previousWithdrawal, setPreviousWithdrawal] = useState(withdrawalLog);
	const currencyState = useSelector((state) => state.currency);
	const [anchorEl, setAnchorEl] = useState(null);
	const [definitionContent, setDefinitionContent] = useState('');
	const [anchorElDefinition, setAnchorElDefinition] = useState(null);
	const [definitionHeading, setDefinitionHeading] = useState('');
	const currencyByAddress = useCallback(
		(currency) => {
			const currencies = Object.values(currencyState);
			for (let i = 0; i < currencies.length; i++) {
				if (currencies[i].ContractAddress === currency) {
					return currencies[i];
				}
			}
			return {};
		},
		[currencyState]
	);

	const nativeCurrencyAddress = useCallback(() => {
		const currencies = Object.values(currencyState);
		for (let i = 0; i < currencies.length; i++) {
			if (currencies[i].Native === true) {
				console.log(' Native found ', currencies[i]);
				return currencies[i];
			}
		}
		return {};
	}, [currencyState]);

	const handleClickDefinition = (event, heading, component) => {
		setAnchorElDefinition(event.currentTarget);
		setDefinitionHeading(heading);
		setDefinitionContent(component);
		event.currentTarget.style.fill = '#5155D4';
	};

	const handleCloseDefinition = () => {
		anchorElDefinition.style.fill = '#484848';
		setAnchorElDefinition(null);
	};

	//utilities
	function findDateFromTimeStamp(T) {
		var Datetime1 = new Date(T * 1000).toLocaleDateString();
		var Datetime2 = new Date(T * 1000).toLocaleString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		});
		var Datefinal = Datetime1 + ' ' + Datetime2;
		return Datefinal;
	}

	function formatDateAndTime(dateStr) {
		const dateObj = new Date(dateStr);

		const formattedDate = dateObj.toLocaleDateString('en-US', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});

		const formattedTime = dateObj.toLocaleTimeString('en-US', {
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
		});

		return formattedDate + ', ' + formattedTime;
	}

	function findtokenswithdrawn(tokens) {
		let str = '';
		let arr = tokens.map((token) => {
			return token.CurrencyId;
		});
		for (let item of arr) {
			str += currencyState[item].Abbreviation;
			str += ', ';
		}
		return str.slice(0, -2);
	}

	function findtotalwithdrawnamount(tokens) {
		let sum = 0;
		let tokenusdarr = tokens.map((token) => {
			return calculatePrice(
				token.Amount,
				exchangeRate[token.CurrencyId],
				currencyState[token.CurrencyId].Decimal
			);
		});

		for (let item of tokenusdarr) {
			sum += parseFloat(item);
		}

		return sum.toFixed(usdDecimalLen);
	}
	const openEtherscan = (hash, active) => {
		if (active) window.open(process.env.REACT_APP_ETHERSCAN_URL + 'tx/' + hash, '_blank');
	};

	const handleClose = () => {
		if (anchorEl) anchorEl.style.fill = '#484848';
		setAnchorEl(null);
	};

	function findstatusprocessingcount(tokens) {
		let count = 0;
		for (let item of tokens) {
			if (item.Status === 'Processing') {
				count++;
			}
		}
		return (
			<>
				{count !== tokens.length ? (
					<>
						<Typography sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '16px' }}>
							{tokens.filter((e) => e.Status === 'SUCCESS').length}/{tokens.length} Completed
						</Typography>
					</>
				) : (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}>
						<ProcessingIcon sx={{ width: '15px', height: '15px' }} />
						<Typography sx={{ display: 'flex', fontWeight: 400, fontSize: '16px', lineHeight: '16px' }}>
							<Typography sx={{ display: 'flex', padding: '0px 5px' }}>
								{count}/{tokens.length}{' '}
							</Typography>
							Processing
						</Typography>
					</Box>
				)}
			</>
		);
	}

	return (
		<Box sx={{ marginTop: '62px' }}>
			<Typography sx={{ fontSize: '18px', lineHeight: '18px', fontWeight: 700, marginBottom: '24px' }}>
				Previous Withdrawals
			</Typography>
			<TableContainer style={{ marginBottom: '120px' }}>
				<Table
					sx={{
						border: '1px solid #E8E8E8',
						borderRadius: '8px',
						overflow: 'hidden',
						minWidth: 650,
						borderCollapse: 'separate',
					}}>
					{/* {<TableHeader />} */}
					<TableHead>
						<TableRow sx={{ backgroundColor: '#F8F8FF' }}>
							<TableCell width="10%">
								<Typography
									sx={{
										fontSize: '14px',
										lineHeight: '16px',
										fontWeight: 700,
										textAlign: 'left',
										padding: '6px',
									}}>
									Date
								</Typography>
							</TableCell>
							<TableCell width="20%">
								<Typography
									sx={{
										fontSize: '14px',
										lineHeight: '16px',
										fontWeight: 700,
										textAlign: 'left',
										padding: '6px',
									}}>
									Tokens withdrawn
								</Typography>
							</TableCell>
							<TableCell width="20%">
								<Typography
									sx={{
										fontSize: '14px',
										lineHeight: '16px',
										fontWeight: 700,
										textAlign: 'left',
										padding: '6px',
										display: 'flex', // New line
										alignItems: 'center', // New line
									}}>
									Withdrawn amount
									<InfoIcon
										style={{
											width: '18px',
											height: '18px',
											marginLeft: '5px',
											cursor: 'pointer',
										}}
										onClick={(e) => {
											handleClickDefinition(
												e,
												'Withdrawn Amount',
												<DefinitionsWrap content={definitions.wa} />
											);
										}}
									/>
								</Typography>
							</TableCell>
							<TableCell width="15%">
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										alignItems: 'center',
									}}>
									<Typography
										sx={{
											fontSize: '14px',
											lineHeight: '16px',
											fontWeight: 700,
											textAlign: 'left',
											padding: '6px',
											display: 'flex',
											alignItems: 'center',
										}}>
										Nume fee
										<InfoIcon
											style={{
												width: '18px',
												height: '18px',
												marginLeft: '5px',
												cursor: 'pointer',
											}}
											onClick={(e) => {
												handleClickDefinition(
													e,
													'Nume fee',
													<DefinitionsWrap content={definitions.nf} />
												);
											}}
										/>
									</Typography>
								</Box>
							</TableCell>
							<TableCell width="15%">
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										display: 'flex', // New line
										alignItems: 'center', // New line
									}}>
									<Typography
										sx={{
											fontSize: '14px',
											lineHeight: '16px',
											fontWeight: 700,
											textAlign: 'left',
											padding: '6px',
										}}>
										Status
										<InfoIcon
											style={{
												width: '18px',
												height: '18px',
												marginLeft: '5px',
												cursor: 'pointer',
											}}
											onClick={(e) =>
												handleClickDefinition(
													e,
													'Withdrawal status',
													<WithdrawalStatusDefinition />
												)
											}
										/>
									</Typography>
								</Box>
							</TableCell>
							<TableCell width="15%">
								<Typography
									sx={{
										fontSize: '14px',
										lineHeight: '16px',
										fontWeight: 700,
										textAlign: 'left',
										padding: '6px',
									}}></Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{previousWithdrawal.map((val) => {
							const nativeCurrency = nativeCurrencyAddress();
							return (
								<React.Fragment key={val.TxHash}>
									<TableRow
										sx={{ backgroundColor: val.length > 1 && val.show ? '#EFEFFB' : '#FFFFFF' }}>
										<TableCell>{formatDateAndTime(val.Timestamp)}</TableCell>
										<TableCell>
											<div style={{ display: 'flex' }}>
												{getTokenLogo(currencyByAddress(val.Currency).Abbreviation)}
												{currencyByAddress(val.Currency).Abbreviation}
											</div>
										</TableCell>
										<TableCell>
											<div>
												{' '}
												{(
													val.Amount / Math.pow(10, currencyByAddress(val.Currency).Decimal)
												).toFixed(tokenDecimalLen)}{' '}
												{currencyByAddress(val.Currency).Abbreviation}
											</div>

											<div style={{ color: '#767676' }}>
												$
												{calculatePrice(
													val.Amount,
													exchangeRate[currencyByAddress(val.Currency).ID],
													currencyState[currencyByAddress(val.Currency).ID].Decimal
												)}
											</div>
										</TableCell>
										<TableCell>
											<div>
												{' '}
												{(
													convertToWei(
														nativeCurrency.DefaultWithdrawalFees,
														nativeCurrency.Decimal
													) / Math.pow(10, nativeCurrency.Decimal)
												).toFixed(tokenDecimalLen)}{' '}
												{nativeCurrency.Abbreviation}
											</div>

											<div style={{ color: '#767676' }}>
												$
												{calculatePrice(
													convertToWei(
														nativeCurrency.DefaultWithdrawalFees,
														nativeCurrency.Decimal
													),
													exchangeRate[nativeCurrency.ID],
													currencyState[nativeCurrency.ID].Decimal
												)}
											</div>
										</TableCell>
										<TableCell>
											{val.SettlementStatus ? (
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'flex-start',
														alignItems: 'center',
													}}>
													<Typography
														sx={{
															display: 'inline',
															color:
																val.SettlementStatus === 'NOTARIZED'
																	? '#0EA90B'
																	: '#9A6700',
															border:
																val.SettlementStatus === 'NOTARIZED'
																	? '1px solid #0EA90B'
																	: '1px solid #9A6700',
															borderRadius: '20px',
															padding: '4px 16px',
														}}>
														{val.SettlementStatus === 'NOTARIZED'
															? 'Completed'
															: 'Processing'}
													</Typography>
												</Box>
											) : (
												{
													//findstatusprocessingcount(val)
												}
											)}
										</TableCell>
										<TableCell>
											<Box
												variant=""
												sx={{
													textTransform: 'none',
													color: val.SettlementStatus === 'NOTARIZED' ? '#0066C0' : '#0066C0',
													cursor: 'pointer',
												}}>
												<span
													onClick={() =>
														openEtherscan(
															val.TxHash,
															true
															/* 															val.SettlementStatus === 'NOTARIZED'
															 */
														)
													}>
													View transaction
												</span>
											</Box>
										</TableCell>
									</TableRow>
								</React.Fragment>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<Tooltip
				heading={definitionHeading}
				anchorEl={anchorElDefinition}
				handleClose={handleCloseDefinition}
				content={definitionContent}
			/>
		</Box>
	);
}

export default PreviousWithdrawals;
