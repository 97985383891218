import React, { useEffect } from 'react';
import { Typography, Button, Container, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../assets/nume-business-logo.png';
import DoneIcon from '@mui/icons-material/Done';

export const EmailVerified = () => {
	const navigate = useNavigate();
	const handleContinue = async () => {
		navigate('/', { state: { showLogin: true } });
	};

	const search = useLocation().search;
	const code = new URLSearchParams(search).get('code');
	const email = new URLSearchParams(search).get('userName');

	/* 	const verifyUser = async () => {
		try {
			await Auth.confirmSignUp(email, code);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		verifyUser();
	}); */
	return (
		<div>
			<img
				src={Logo}
				alt="logo"
				style={{
					margin: '20px 40px',
					position: 'absolute',
					maxWidth: '200px',
				}}
			/>
			<Container
				style={{
					minHeight: '100vh',
					minWidth: '100vw',
					display: 'flex',
				}}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						margin: 'auto',
					}}>
					<Box
						sx={{
							// height: '250px',
							width: '600px',
							padding: '20px',
							border: '1px solid #E8E8E8',
							borderRadius: '4px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							p: 4,
							boxShadow: ' 0px 4px 12px rgba(0, 0, 0, 0.08);',
							// alignItems: 'center',
						}}>
						<>
							<div style={{ textAlign: 'center' }}>
								<Typography component="h1" variant="h4" sx={{ pb: 2, fontSize: '28px' }}>
									Email verified
								</Typography>

								<Box
									sx={{
										padding: '20px',
										background: '#fff',
										borderRadius: '50%',
										height: '100px',
										width: '100px',
										border: '2px solid #0EA90B',
										margin: '25px auto',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<DoneIcon sx={{ fontSize: '100px', color: '#0EA90B' }} />
								</Box>
								<Typography variant="body1">Thanks for verifying email</Typography>
							</div>
						</>
						<div style={{ textAlign: 'center' }}>
							<Button
								onClick={handleContinue}
								sx={{
									fontWeight: 500,
									height: '45px',
									textTransform: 'none',
									margin: '20px',
									mt: 4,
									width: '200px',
								}}
								variant="contained">
								Continue to Sign In
							</Button>
						</div>
					</Box>
				</Box>
			</Container>
		</div>
	);
};
