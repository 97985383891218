import React from 'react';
import { CircularProgress } from '@mui/material';

export const Loader = () => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center' }}>
			<CircularProgress style={{ color: 'rgba(81, 85, 212, 1)' }} />
		</div>
	);
};
