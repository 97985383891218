import React, { useState } from 'react';
import { Typography, Button, Container, Box, InputAdornment, Alert } from '@mui/material';
import { passwordValidator } from '../../utils';
import { StyledTextField } from '../../components/StyledTextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { PasswordCheck } from '../../components/PasswordCheck';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/nume-business-logo.png';
import { CustomButton } from '../../components/CustomButton';
import { dataFetch } from '../../utils';
import { useSelector } from 'react-redux';

const useHelperTextStyles = makeStyles(() => ({
	root: {
		marginLeft: '0px !important',
		marginRight: '0px !important',
		marginTop: '0px !important',
		paddingTop: '4px',
	},
}));

export const ChangePassword = () => {
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [passwordFocus, setPasswordFocus] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState('');
	const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
	const [focusCounter, setFocusCounter] = useState({
		confirmPassword: 0,
		password: 0,
		currentPassword: 0,
	});
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const [currentPasswordFocus, setCurrentPasswordFocus] = useState(false);
	const [globalError, setGlobalError] = useState('');

	const navigate = useNavigate();
	const helperTextStyles = useHelperTextStyles();
	const confirmPasswordError = () =>
		password !== confirmPassword && (confirmPassword.length > 0 || focusCounter.confirmPassword > 0);
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const [isLoading, setIsLoading] = useState(false);

	const preventCursorChange = (event) => {
		event.preventDefault();
	};
	const merchant = useSelector((state) => state.merchant);
	const changePwd = async () => {
		if (currentPassword.length === 0 || password.length === 0 || confirmPassword.length === 0) {
			setGlobalError('Please enter valid entry for passwords.');
			return;
		}
		if (confirmPasswordError() || !passwordValidator(password).pass) {
			console.log(!confirmPasswordError(), !passwordValidator(password).pass);
			setFocusCounter({ ...focusCounter, password: 1, confirmPassword: 1 });
			setIsLoading(false);
			return;
		}
		setIsLoading(true);
		/* 		Auth.currentAuthenticatedUser()
			.then((user) => {
				return Auth.changePassword(user, currentPassword, password);
			})
			.then((data) => {
				console.log(data);
				return dataFetch('user/change-password', {}, 'POST', merchant);
			})
			.then(() => {
				setIsLoading(false);
				navigate('/', {
					state: { toast: 'Password has been changed successfully!', type: 'success' },
				});
			})
			.catch((err) => {
				setIsLoading(false);
				console.log(err);
			}); */
	};
	return (
		<div>
			<img
				src={Logo}
				alt="logo"
				style={{
					margin: '20px 40px',
					position: 'absolute',
					maxWidth: '200px',
				}}
			/>
			<Container
				style={{
					minHeight: '100vh',
					minWidth: '100vw',
					display: 'flex',
				}}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						margin: 'auto',
					}}>
					<Box
						sx={{
							// height: '250px',
							width: '450px',
							padding: '20px',
							border: '1px solid #E8E8E8',
							borderRadius: '4px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							p: 4,
							boxShadow: ' 0px 4px 12px rgba(0, 0, 0, 0.08);',
							// alignItems: 'center',
						}}>
						<Box>
							<Typography component="h1" variant="h4" sx={{ pb: 2, fontSize: '28px' }}>
								Change password
							</Typography>

							<Typography variant="body1">Create a strong password to protect your account.</Typography>
							{globalError.length > 0 && (
								<Alert severity="error" sx={{ mt: 1, mb: 1 }}>
									{globalError}
								</Alert>
							)}
							<StyledTextField
								sx={{ background: '#fff' }}
								onChange={(e) => {
									setGlobalError('');
									setCurrentPassword(e.target.value);
								}}
								margin="normal"
								onBlur={() => {
									setCurrentPasswordFocus(false);
									setFocusCounter({ ...focusCounter, currentPassword: 1 });
								}}
								onFocus={() => setCurrentPasswordFocus(true)}
								fullWidth
								name="current-password"
								placeholder="Current password"
								type={showCurrentPassword ? 'text' : 'password'}
								id="current-password"
								autoComplete="current-password"
								FormHelperTextProps={{
									classes: {
										root: helperTextStyles.root,
									},
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{focusCounter.currentPassword > 0 || currentPassword.length > 0 ? (
												<div
													aria-label="toggle password visibility"
													onClick={() => setShowCurrentPassword(!showCurrentPassword)}
													style={{ cursor: 'pointer' }}
													onMouseDown={preventCursorChange}
													onMouseUp={preventCursorChange}>
													{showPassword ? (
														<Visibility
															sx={{
																color: currentPasswordFocus ? '#5155D4' : 'inherit',
															}}
														/>
													) : (
														<VisibilityOff
															sx={{
																color: currentPasswordFocus ? '#5155D4' : 'inherit',
															}}
														/>
													)}
												</div>
											) : (
												<></>
											)}
										</InputAdornment>
									),
								}}
							/>
							<StyledTextField
								sx={{ background: '#fff' }}
								onChange={(e) => {
									setGlobalError('');
									setPassword(e.target.value);
								}}
								margin="normal"
								fullWidth
								onBlur={() => {
									setPasswordFocus(false);
									setFocusCounter({ ...focusCounter, password: 1 });
								}}
								onFocus={() => setPasswordFocus(true)}
								name="password"
								placeholder="Password"
								type={showPassword ? 'text' : 'password'}
								id="password"
								autoComplete="current-password"
								error={!passwordValidator(password).pass && focusCounter.password > 0}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{focusCounter.password > 0 || password.length > 0 ? (
												!passwordValidator(password).pass && !passwordFocus ? (
													<CancelOutlinedIcon sx={{ color: 'red' }} />
												) : (
													<div
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														style={{ cursor: 'pointer' }}
														onMouseDown={preventCursorChange}
														onMouseUp={preventCursorChange}>
														{showCurrentPassword ? (
															<Visibility
																sx={{
																	color: passwordFocus ? '#5155D4' : 'inherit',
																}}
															/>
														) : (
															<VisibilityOff
																sx={{
																	color: passwordFocus ? '#5155D4' : 'inherit',
																}}
															/>
														)}
													</div>
												)
											) : (
												<></>
											)}
										</InputAdornment>
									),
								}}
							/>
							{(focusCounter.password > 0 || passwordFocus) &&
							(!passwordValidator(password).pass || passwordFocus) ? (
								<PasswordCheck password={password} />
							) : (
								<></>
							)}
							<StyledTextField
								sx={{ background: '#fff', mb: 4 }}
								onChange={(e) => {
									setGlobalError('');
									setConfirmPassword(e.target.value);
								}}
								margin="normal"
								onBlur={() => {
									setConfirmPasswordFocus(false);
									setFocusCounter({ ...focusCounter, confirmPassword: 1 });
								}}
								onFocus={() => setConfirmPasswordFocus(true)}
								fullWidth
								name="confirm-password"
								placeholder="Confirm password"
								type={showConfirmPassword ? 'text' : 'password'}
								id="confirm-password"
								autoComplete="confirm-password"
								error={confirmPasswordError()}
								helperText={confirmPasswordError() ? 'Passwords do not match, please try again.' : null}
								FormHelperTextProps={{
									classes: {
										root: helperTextStyles.root,
									},
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{(focusCounter.confirmPassword > 0 && confirmPassword.length > 0) ||
											confirmPassword.length > 0 ? (
												<div
													aria-label="toggle password visibility"
													onClick={() => setShowConfirmPassword(!showConfirmPassword)}
													style={{ cursor: 'pointer' }}
													onMouseDown={preventCursorChange}
													onMouseUp={preventCursorChange}>
													{showConfirmPassword ? (
														<Visibility
															sx={{
																color: confirmPasswordFocus ? '#5155D4' : 'inherit',
															}}
														/>
													) : (
														<VisibilityOff
															sx={{
																color: confirmPasswordFocus ? '#5155D4' : 'inherit',
															}}
														/>
													)}
												</div>
											) : (
												<></>
											)}
										</InputAdornment>
									),
								}}
							/>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0 }}>
								<Button
									style={{
										fontWeight: 500,
										height: '45px',
										margin: '20px 0',
										width: '200px',
										textTransform: 'none',
									}}
									onClick={() => {
										navigate('/');
									}}
									variant="outlined">
									Back to sign in
								</Button>
								<CustomButton
									isLoading={isLoading}
									sx={{
										fontWeight: 500,
										height: '45px',
										margin: '20px 0',
										width: '200px',
										display: 'block',
									}}
									onClick={changePwd}
									variant="contained">
									Reset Password
								</CustomButton>
							</Box>
						</Box>
					</Box>
				</Box>
			</Container>
		</div>
	);
};
