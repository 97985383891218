const initialstate = false;
const firstTimeUserReducer = (state = initialstate, action) => {
	switch (action.type) {
		case 'SET_FIRST_TIME_USER':
			return true;
		case 'UNSET_FIRST_TIME_USER':
			return initialstate;
		default:
			return state;
	}
};
export default firstTimeUserReducer;
