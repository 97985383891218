import React, { useState, useEffect } from 'react';
import { passwordValidator } from '../../utils';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const PasswordCheck = ({ password }) => {
	const [errors, setErrors] = useState({});
	useEffect(() => {
		setErrors(passwordValidator(password).errors);
	}, [password]);
	return (
		<div style={{ textAlign: 'left', padding: '0 10px' }}>
			{Object.keys(errors).map((e) => {
				return (
					<div
						key={e}
						style={{
							color: errors[e][0] ? 'red' : 'green',
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							marginBottom: '4px',
						}}>
						{errors[e][0] ? <CancelOutlinedIcon /> : <CheckCircleOutlineIcon />}
						<span style={{ marginLeft: '4px', color: '#484848' }}>{errors[e][1]}</span>
					</div>
				);
			})}
		</div>
	);
};
