const initialState = {
	isWithdrawal: false,
};

const withdrawalReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_IS_WITHDRAWAL':
			return {
				...state,
				isWithdrawal: action.payload,
			};
		default:
			return state;
	}
};

export default withdrawalReducer;
