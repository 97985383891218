const initialstate = {};
const cognitoTokenReducer = (state = initialstate, action) => {
	switch (action.type) {
		case 'SET_COGNITO_TOKEN':
			return action.payload;
		case 'UNSET_COGNITO_TOKEN':
			return initialstate;
		default:
			return state;
	}
};
export default cognitoTokenReducer;
