import React, { useState, useEffect } from 'react';
import {
	Box,
	Typography,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Checkbox,
} from '@mui/material';
import { useSelector } from 'react-redux';

import { Tooltip } from './Tooltip';

import { dataFetch, tokenDecimalLen, totalPrice, calculatePrice, getTokenLogo } from '../../utils';
import { Loader } from '../../components/Loader';
import { useNavigate, useLocation } from 'react-router-dom';

import PreviousWithdrawals from './PreviousWithdrawals';

import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { useAccount, useDisconnect, useSigner, erc20ABI, useNetwork } from 'wagmi';
import { switchNetwork } from '@wagmi/core';
import { ethers, Contract } from 'ethers';
import { useDispatch } from 'react-redux';
import { setIsWithdrawal } from '../../actions/withdrawals';

let _cf = (function () {
	function _shift(x) {
		let parts = x.toString().split('.');
		return parts.length < 2 ? 1 : Math.pow(10, parts[1].length);
	}
	return function () {
		return Array.prototype.reduce.call(
			arguments,
			function (prev, next) {
				return prev === undefined || next === undefined ? undefined : Math.max(prev, _shift(next));
			},
			-Infinity
		);
	};
})();

Math.a = function () {
	let f = _cf.apply(null, arguments);
	if (f === undefined) return undefined;
	function cb(x, y, i, o) {
		return x + f * y;
	}
	return Array.prototype.reduce.call(arguments, cb, 0) / f;
};

Math.s = function (l, r) {
	let f = _cf(l, r);
	return (l * f - r * f) / f;
};

const Breakdown = ({ breakDownVal }) => {
	return (
		<TableContainer>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell style={{ fontWeight: 500 }}>Token</TableCell>
						<TableCell style={{ fontWeight: 500 }} align="left">
							Amount
						</TableCell>
						<TableCell style={{ fontWeight: 500 }} align="left">
							USD
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{breakDownVal.map((e, i) => {
						return (
							<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component="th" scope="row">
									{e.name}
								</TableCell>
								<TableCell align="left">{e.value}</TableCell>
								<TableCell align="left">${e.valueUSD}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export const WithdrawFund = () => {
	const location = useLocation();
	// console.log(location);
	// let {iAB} =location.state
	const tempbreakdownval = [
		{ name: 'WBTC', value: 0.0414, valueUSD: 892.45 },
		{ name: 'DAI', value: 750.66, valueUSD: 750.64 },
		{ name: 'USDT', value: 722.51, valueUSD: 722.49 },
		{ name: 'USDC', value: 206.46, valueUSD: 206.45 },
	];
	const [exchangeRate, setExchangeRate] = useState({});
	const [availableBalance, setAvailableBalance] = useState(0);
	// const [previousWithdrawal, setPreviousWithdrawal] = useState([]);
	const [unAvailableBalance, setUnAvailableBalance] = useState(0);
	const [availableBalanceBreakdown, setAvailableBalanceBreakdown] = useState([]);
	const [withdrawalLog, setWithdrawalLog] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorunav, setanchorunav] = useState(null);
	const [anchorav, setanchorav] = useState(null);
	const [anchorminneeded, setanchorminneeded] = useState(null);
	//const { data: signer } = useSigner();

	const [available_icon, setavailable_icon] = useState(false);
	const [unavailable_icon, setunavailable_icon] = useState(false);
	const { address } = useAccount();

	const [breakDownVal, setBreakDownVal] = useState([...tempbreakdownval]);
	const [isLoading, setIsLoading] = useState(true);
	const [activetab, setactivetab] = useState(true);
	const [alltokenschecked, setalltokenschecked] = useState(false);
	const [disablewithdrawbutton, setdisablewithdrawbutton] = useState(true);

	const merchant = useSelector((state) => state.merchant);
	const currencyState = useSelector((state) => state.currency);
	const navigate = useNavigate();
	const { data: signer } = useSigner();
	const { chain } = useNetwork();
	const isWithdrawal = useSelector((state) => state.withdrawal.isWithdrawal);
	console.log(' isWithdrawal on Withdrawal funds ', isWithdrawal);
	const dispatch = useDispatch();
	dispatch(setIsWithdrawal(true));

	const handleActiveTab = (params) => {
		if (params === 'available') {
			setactivetab(true);
		} else {
			setactivetab(false);
		}
	};

	const handleAvailableClick = (event) => {
		setanchorav(event.currentTarget);
		setavailable_icon(true);
		event.currentTarget.style.fill = '#5155D4';
	};

	const handleUnavailableClick = (event) => {
		setanchorunav(event.currentTarget);
		setunavailable_icon(true);
		event.currentTarget.style.fill = '#5155D4';
	};

	const handleClose = () => {
		if (anchorEl) anchorEl.style.fill = '#484848';
		if (anchorav) anchorav.style.fill = '#484848';
		if (anchorminneeded) anchorminneeded.style.fill = '#484848';
		if (anchorunav) anchorunav.style.fill = '#484848';
		setAnchorEl(null);
		setanchorunav(null);
		setanchorav(null);
		setanchorminneeded(null);
		setavailable_icon(false);
		setunavailable_icon(false);
		console.log('close clicked');
	};

	const handlealltokenchecked = () => {
		let NewObj;
		if (!alltokenschecked) {
			setalltokenschecked(true);
			NewObj = availableBalanceBreakdown.map((item, indx) => {
				return { ...item, tokenchecked: item.Amount > item.networkfeeamount };
			});
		} else {
			setalltokenschecked(false);
			NewObj = availableBalanceBreakdown.map((item, indx) => {
				return { ...item, tokenchecked: false };
			});
		}

		setAvailableBalanceBreakdown(NewObj);
	};

	const handlesingletokencheck = (currencyid) => {
		console.log('------------>', availableBalanceBreakdown);
		setalltokenschecked(false);
		let newObj = availableBalanceBreakdown.map((item, indx) => {
			if (item['CurrencyId'] === currencyid) {
				return { ...item, tokenchecked: !item.tokenchecked };
			} else return { ...item };
		});
		// console.log(newObj)
		setAvailableBalanceBreakdown(newObj);
	};

	const initiateWithdraw = async (wItem) => {
		try {
			console.log(wItem);
			console.log('chain.id ', chain.id);
			let famnt = wItem.Amount / Math.pow(10, currencyState[wItem.CurrencyId].Decimal).toFixed(tokenDecimalLen);
			console.log(' famnt ', famnt);
			/* 			if (chain && chain.id !== 710) {
				await switchNetwork(710);
			} */
			//await switchNetworkPromise;
			if (chain && chain.id !== 710) {
				const network = await switchNetwork({
					chainId: 710,
				});
			}
			var hash;
			if (wItem.ContractAddress.toLowerCase() === '0xee146fac7b2fce5fdbe31c36d89cf92f6b006f80') {
				// notify("MATIC withdrawals are paused currecntly");
				const tx = {
					to: address,
					value: ethers.utils.parseUnits(famnt.toString(), currencyState[wItem.CurrencyId].Decimal),
				};
				const transfer = await signer.sendTransaction(tx);
				//setTxHash(transfer.hash);
				await transfer.wait();
				// console.log(transfer.hash)
				console.log('withdraw success ', transfer.hash);
				hash = transfer.hash;
			} else {
				console.log(wItem.ContractAddress);
				console.log(famnt.toString());
				console.log(currencyState[wItem.CurrencyId].Decimal);
				const amountToWithdraw = ethers.utils.parseUnits(
					famnt.toString(),
					currencyState[wItem.CurrencyId].Decimal
				);
				if (!signer) {
					console.log('signer unavailable ');
				}
				const tx = {
					to: wItem.ContractAddress,
					gasLimit: '0x61a8',
					maxFeePerGas: '0x3a352944000',
					maxPriorityFeePerGas: '0x0',
					data:
						'0xa9059cbb' +
						ethers.utils
							.solidityPack(['address', 'uint256'], [address, amountToWithdraw])
							.slice(2)
							.padStart(128, '0'),
				};

				const transfer = await signer.sendTransaction(tx);
				console.log(transfer.hash);
				await transfer.wait();
				console.log('withdraw success ', transfer.hash);
				hash = transfer.hash;
			}
			navigate('/withdraw-confirmation', {
				state: {
					currencyState,
					exchangeRate,
					initialAmount: wItem.Amount.toString(),
					currencyId: wItem.CurrencyId,
					chain: 'Polygon',
					txhash: hash,
				},
			});
		} catch (error) {
			console.log(error);
			console.log(
				'Transaction request failed. Your funds were not withdrawn to the Polygon network. Please try again.'
			);
		}
	};

	// fetching values on load
	useEffect(() => {
		if (merchant && Object.keys(merchant).length !== 0) {
			async function fetchData() {
				try {
					let data = await dataFetch(
						process.env.REACT_APP_PBE_URL + `fetch-user-balance/${address}`,
						{},
						'GET',
						{}
					);
					let resAvailableBalanceBreakdown = data.json.message.UserBalances || [];

					let T = resAvailableBalanceBreakdown;
					for (let item of T) {
						item['tokenchecked'] = false;
						/* 						item['networkfeeamount'] =
													networkFeeData.json.message.NetworkFees[item.CurrencyId].GasPriceAverage; // networkFeeValue(item.CurrencyId);
												item['networkFeeFirstId'] = networkFeeData.json.message.NetworkFees[item.CurrencyId].FirstID;
												item['networkFeeLastId'] = networkFeeData.json.message.NetworkFees[item.CurrencyId].FirstID; */
					}

					setAvailableBalanceBreakdown(T);
					data = await dataFetch(process.env.REACT_APP_DAPP_URL + 'get-exchange-rates', {}, 'GET', {});
					let resExchangeRates = data.json.message.ExchangeRates || [];
					setExchangeRate(resExchangeRates);

					setAvailableBalance(totalPrice(resAvailableBalanceBreakdown, currencyState, resExchangeRates));
					//					setUnAvailableBalance(totalPrice(resMerchantPendingwalletBalance, currencyState, resExchangeRates));

					// Prev : MerchantWithdrawalLogs map[string][]MerchantWithdrawLog
					// Now : type UserActivitiesResponseBody struct { UserActivities []UserActivity DpOffset       uint WOffset        uint }
					data = await dataFetch(
						process.env.REACT_APP_PBE_URL +
							`explorer/activity/${address}?limit=10&dpOffset=100000&wOffset=0&txOffset=100000`,
						{},
						'GET',
						{}
					);
					if (data.json.message.UserActivities) {
						// Check if UserActivities field exists
						setWithdrawalLog(data.json.message.UserActivities);
						console.log('withdrawal logs:', data.json.message.UserActivities);
					} else {
						console.log('Error:', data.json.message); // Log the error message
					}
					setIsLoading(false);
				} catch (error) {
					console.log(error);
				}
			}
			fetchData();
		}
	}, [merchant, currencyState]);

	/* 	useEffect(() => {
		if (chain && chain.id) {
			if (chain && chain.id !== 710) {
				console.log("Switch withdraw")
				const network = switchNetwork({
					chainId: 710,
				  })
			}
		}
	  }, [merchant, chain, currencyState]); */

	//keeping a track on enable disable add currency
	useEffect(() => {
		let checkedarray = availableBalanceBreakdown.map((item) => {
			return item.Amount > item.networkfeeamount;
		});
		let res = checkedarray.includes(true);
		setdisablewithdrawbutton(!res);
		return () => {};
	}, [availableBalanceBreakdown]);

	useEffect(() => {
		if (location.state !== null && 'showAvailable' in location.state) {
			setactivetab(location.state.showAvailable);
		}
		return () => {};
	}, []);

	const handleWithdraw = (item) => {
		const balance = [
			{
				...item,
			},
		];
		navigate('/withdraw-available', {
			state: {
				availableBalance,
				availableBalanceBreakdown: balance,
				exchangeRate,
				currencyState,
			},
		});
	};

	if (isLoading) {
		return <Loader />;
	}
	return (
		<Box sx={{ padding: '40px' }}>
			<Box sx={{ padding: '8px' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: '20px',
					}}>
					<Typography
						variant="h4"
						sx={{
							margin: '0.5rem',
							marginLeft: 0,
						}}>
						Balance
					</Typography>
				</Box>
			</Box>

			<Box sx={{ padding: '30px', backgroundColor: '#F8F8FF' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-startr' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							alignItems: 'flex-start',
						}}>
						<Typography
							sx={{
								fontSize: '14px',
								lineHeight: '18px',
								letterSpacing: '0.5px',
								textAlign: 'left',
								position: 'relative',
								display: 'flex',
								alignItems: 'center',
							}}>
							Available Balance
							<InfoIcon
								style={{
									color: available_icon ? '#5155D4' : '#4A3B59',
									width: '18px',
									height: '18px',
									marginLeft: '5px',
									cursor: 'pointer',
								}}
								onClick={(e) => {
									handleAvailableClick(e);
								}}
							/>
						</Typography>
						<Box>
							{location.state !== null && location.state.from === 'withdraw-confirmation' && false ? (
								<Typography
									style={{
										fontSize: '30px',
										fontWeight: 500,
										lineHeight: '37px',
										letterSpacing: '0.5px',
										textAlign: 'left',
										marginTop: '10px',
										marginBottom: '31px',
									}}>
									${'0.00'}
								</Typography>
							) : (
								<Typography
									style={{
										fontSize: '30px',
										fontWeight: 500,
										lineHeight: '37px',
										letterSpacing: '0.5px',
										textAlign: 'left',
										marginTop: '10px',
										marginBottom: '31px',
									}}>
									${availableBalance}
									{/* ${2572.04} */}
								</Typography>
							)}
						</Box>
					</Box>
				</Box>

				<TableContainer>
					<Table
						sx={{
							// minWidth: 650,
							border: `${
								location.state !== null && location.state.from === 'withdraw-confirmation' && false
							}? "" :'1px solid #d1d1d1'`,
							borderCollapse: 'separate',
							borderRadius: '8px',
							backgroundColor: 'white',
						}}>
						{location.state !== null && location.state.from === 'withdraw-confirmation' && false ? (
							<Typography
								sx={{
									display: 'grid',
									placeItems: 'center',
									backgroundColor: '#F8F8FF',
									padding: '77px',
									borderTop: '0.5px solid rgba(74, 59, 89, 1)',
								}}>
								No funds are available to withdraw yet.
							</Typography>
						) : (
							<>
								<TableHead>
									<TableRow>
										<TableCell width="25%" style={{ fontWeight: 500 }} align="left">
											Token Name
										</TableCell>
										<TableCell width="25%" style={{ fontWeight: 500 }} align="left">
											Token Value
										</TableCell>
										<TableCell width="25%" style={{ fontWeight: 500 }} align="left">
											USD Value
										</TableCell>
										<TableCell width="25%" style={{ fontWeight: 500 }} align="left">
											Action
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{availableBalanceBreakdown.map((e, indx) => {
										return (
											<TableRow
												key={indx}
												sx={{
													'&:last-child td, &:last-child th': { border: 0 },
													backgroundColor:
														e.Amount < e.networkfeeamount ? '#FBFBFB' : '#FFFFFF',
												}}>
												<TableCell sx={{ fontSize: '18px' }} component="th" scope="row">
													<Box sx={{ display: 'flex', flexDirection: 'row' }}>
														{getTokenLogo(currencyState[e.CurrencyId].Abbreviation)}
														{currencyState[e.CurrencyId].Abbreviation}
													</Box>
												</TableCell>
												<TableCell sx={{ fontSize: '18px' }} align="left">
													{(
														e.Amount / Math.pow(10, currencyState[e.CurrencyId].Decimal)
													).toFixed(tokenDecimalLen)}
												</TableCell>
												<TableCell sx={{ fontSize: '18px' }} align="left">
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'row',
															justifyContent: 'flex-start',
															alignItems: 'center',
															verticalAlign: 'center',
														}}>
														<Typography sx={{ width: '30%', fontSize: '18px' }}>
															$
															{calculatePrice(
																e.Amount,
																exchangeRate[e.CurrencyId],
																currencyState[e.CurrencyId].Decimal
															)}
														</Typography>
														{e.networkfeeamount > e.Amount ? (
															<Box
																sx={{
																	width: '70%',
																	display: 'flex',
																	flexDirection: 'row',
																	justifyContent: 'flex-start',
																	alignItems: 'center',
																}}>
																<Typography sx={{ color: '#CF222E', fontSize: '14px' }}>
																	Minimum $
																	{calculatePrice(
																		e.networkfeeamount,
																		exchangeRate[e.CurrencyId],
																		currencyState[e.CurrencyId].Decimal
																	)}{' '}
																	needed to withdraw
																</Typography>
																<InfoIcon
																	width="15px"
																	height="15px"
																	style={{
																		marginLeft: '6px',
																		cursor: 'pointer',
																		width: '18px',
																		height: '18px',
																	}}
																	onClick={(e) => {
																		setanchorminneeded(e.currentTarget);
																		e.currentTarget.style.fill = '#5155D4';
																	}}
																/>
															</Box>
														) : (
															<></>
														)}
													</Box>
												</TableCell>
												<TableCell sx={{}}>
													<Button
														style={{
															fontWeight: 500,
															height: '45px',
															textTransform: 'none',
														}}
														onClick={() => initiateWithdraw(e)}
														variant="contained"
														disabled={e.Amount == 0}>
														Withdraw to Polygon
													</Button>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</>
						)}
					</Table>
				</TableContainer>
			</Box>

			{Object.keys(withdrawalLog).length > 0 ? (
				<>
					<PreviousWithdrawals exchangeRate={exchangeRate} withdrawalLog={withdrawalLog} networkFee={null} />
				</>
			) : (
				<Box sx={{ mt: 8 }}>
					<Typography
						component="h5"
						variant="h5"
						style={{
							marginBottom: '10px',
						}}>
						Previous withdrawals
					</Typography>
					<Box sx={{ mt: 4, p: 4, textAlign: 'center', background: '#F8F8FF', borderRadius: '8px' }}>
						<Typography variant="body1">You haven't withdrawn any funds yet.</Typography>
					</Box>
				</Box>
			)}

			{
				// <WithdrawAvailable/>
			}
			<Tooltip
				heading=""
				showheading={false}
				anchorEl={anchorav}
				handleClose={handleClose}
				content={<Typography>The amount available to be withdrawn from your account.</Typography>}
				horizontalOrientation="left"
			/>
			<Tooltip
				heading=""
				showheading={false}
				anchorEl={anchorunav}
				handleClose={handleClose}
				content={<Typography>Unavailable balance represents the total amount of pending payments.</Typography>}
				horizontalOrientation="left"
			/>

			<Tooltip
				heading=""
				showheading={false}
				anchorEl={anchorminneeded}
				handleClose={handleClose}
				content={
					<Typography>
						A mimimum amount is enforced so that fees incurred while withdrawing can be covered. Each token
						incurs a different fee so don't be surprised if you see a different minimum for another token.
					</Typography>
				}
				horizontalOrientation="left"
			/>

			{location.state !== null && (
				<Tooltip
					heading="Breakdown"
					showheading={true}
					anchorEl={anchorEl}
					handleClose={handleClose}
					content={<Breakdown breakDownVal={breakDownVal} />}
					horizontalOrientation="left"
				/>
			)}
		</Box>
	);
};
