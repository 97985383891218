import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
	Box,
	Typography,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
} from '@mui/material';

import { Loader } from '../../components/Loader';
import { dataFetch, tokenDecimalLen, totalPrice, calculatePrice, getTokenLogo, usdDecimalLen } from '../../utils';

import { useSelector } from 'react-redux';
import ArrowLeft from '../../assets/arrow-left.svg';
import { Tooltip } from './Tooltip';

// import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { StyledTextField } from '../../components/StyledTextField';
import { CustomButton } from '../../components/CustomButton';

function WithdrawAvailable() {
	const location = useLocation();
	const navigate = useNavigate();
	const merchant = useSelector((state) => state.merchant);
	const [open, setOpen] = React.useState(false);
	const [widthdawalLoading, setWidthdawalLoading] = useState(false);
	let { availableBalanceBreakdown, exchangeRate, currencyState } = location.state;
	console.log('avilable bal', availableBalanceBreakdown);
	let initialAvailableBalanceBreakdown = availableBalanceBreakdown
		.map((item) => {
			return { ...item, tokenaddress: '', showerror: false };
		})
		.filter((item) => {
			return item.tokenchecked === true;
		});
	const [iAB, setIAB] = useState(initialAvailableBalanceBreakdown);

	const initialNetworkFee = availableBalanceBreakdown
		.map((item) => {
			console.log(item);
			return {
				CurrencyId: item.CurrencyId,
				Amount: item.networkfeeamount,
				tokenchecked: item.tokenchecked,
			};
		})
		.filter((item) => {
			return item.tokenchecked === true;
		});

	// const [networkFee, setnetworkFee] = useState(initialNetworkFee);
	// setnetworkFee(initialNetworkFee)
	const networkFee = initialNetworkFee;

	const [isLoading, setIsLoading] = useState(true);
	const [shownetworkdeduction, setshownetworkdeduction] = useState(false);
	const [networkanchor, setnetworkanchor] = useState(null);
	const [reviewWithdrawalDisabled, setReviewWithdrawalDisabled] = useState(true);

	const cancelGoBack = () => {
		let res = iAB.filter((e) => e.tokenaddress !== '').length;
		if (res > 0) setOpen(true);
		else navigate('/withdraw-funds', { state: { from: 'withdraw-available' } });
	};
	const handleClose = (value) => {
		setOpen(false);
	};

	function handle_focus_(indx) {
		setIAB(
			[...iAB].map((object, index) => {
				if (index === indx) {
					return {
						...object,
						showerror: true,
					};
				} else {
					return object;
				}
			})
		);
	}

	function handletokenaddress_(e, currencyname, indx) {
		let val = e.target.value;

		setIAB(
			[...iAB].map((object, inx) => {
				if (inx === indx) {
					return {
						...object,
						tokenaddress: val,
					};
				} else return object;
			})
		);
	}

	const createDataObject = () => {
		// let resultmap = {}
		console.log(networkFee, 'networkFee');
		let resultmap = iAB.map((obj, indx) => {
			// let tt = currencyState[obj.CurrencyId].Abbreviation
			// resultmap[tt]= obj.tokenaddress
			return {
				currencyId: obj.CurrencyId,
				currencyAddress: obj.tokenaddress,
				lastNFId: findnetworkfeeObj(obj.CurrencyId).FirstId,
				firstNFId: findnetworkfeeObj(obj.CurrencyId).LastId,
			};
		});
		let res = {
			merchantWithdrawalRequest: resultmap,
		};
		return res;
	};

	const handlenetworkfeeclose = () => {
		setnetworkanchor(null);
		setshownetworkdeduction(false);
	};

	const sendTokenData = async () => {
		try {
			setWidthdawalLoading(true);
			let body = createDataObject();
			console.log('body object is--->', body);
			let data = await dataFetch('merchant/withdrawal', body, 'POST', merchant);
			if (data.json.statusCode === 400) {
				console.log('Post call failed');
			} else if (data.json.statusCode === 200) {
				console.log('Post call successful redirect to confirm-withdrawal');
				navigate('/withdraw-confirmation', {
					state: {
						iAB,
						currencyState,
						exchangeRate,
						networkFee,
						initialAmount: totalPrice(iAB, currencyState, exchangeRate),
						totalNetworkFee: totalPrice(networkFee, currencyState, exchangeRate),
					},
				});
			}

			setWidthdawalLoading(false);
			console.log('send token success response', data, data.json.statusCode);
		} catch (e) {
			setWidthdawalLoading(false);
			console.log('error sending token data ', e);
			console.log('api call failed');
		}
	};

	function findnetworkfee(currencyid) {
		for (let item of networkFee) {
			if (parseInt(item.CurrencyId) === parseInt(currencyid)) {
				console.log(item);
				return item.Amount;
			}
		}
	}
	function findnetworkfeeObj(currencyid) {
		for (let item of networkFee) {
			if (item.CurrencyId === currencyid) {
				return item;
			}
		}
	}

	function finddynamicheader() {
		let str = ' ';
		if (iAB.length === 1) str += `${currencyState[iAB[0].CurrencyId].Abbreviation}`;
		else if (iAB.length === availableBalanceBreakdown.length) str += 'All Tokens';
		else if (iAB.length >= 2) {
			let currecyArr = iAB.map((item) => {
				return currencyState[item.CurrencyId].Abbreviation;
			});
			for (let i = 0; i < currecyArr.length - 2; i++) {
				str += currecyArr[i] + ',';
			}
			str = str + currecyArr[currecyArr.length - 2] + ' and ' + currecyArr[currecyArr.length - 1];
		}
		return 'Withdraw' + str;
	}

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 1500);
		return () => {
			// setnetworkFee(initialNetworkFee)
		};
	}, []);

	useEffect(() => {
		if (
			iAB.filter(
				(e) =>
					e.tokenaddress !== '' &&
					e.tokenaddress.length === 42 &&
					isHex(e.tokenaddress) &&
					e.tokenaddress.substring(0, 2) === '0x'
			).length === iAB.length
		)
			setReviewWithdrawalDisabled(false);
		else setReviewWithdrawalDisabled(true);

		return () => {
			setReviewWithdrawalDisabled(true);
		};
	}, [iAB]);

	if (isLoading) {
		return <Loader />;
	}
	function isHex(num) {
		return Boolean(num.match(/^0x[0-9a-f]+$/i));
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				position: 'relative',
				height: '100vh',
				padding: '40px 0px',
				overflowY: 'auto',
			}}>
			{!shownetworkdeduction ? (
				<>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							marginLeft: '20px',
							marginBottom: '10px',
						}}>
						<img
							src={ArrowLeft}
							style={{ position: 'absolute', left: '15px', padding: '5px 10px 10px 10px' }}
							alt="info"
							width="32px"
							height="32px"
							onClick={cancelGoBack}
						/>

						<Typography
							variant="h4"
							sx={{
								margin: '0.5rem',
								marginLeft: '40px',
							}}>
							{finddynamicheader()}
						</Typography>
					</Box>

					<Box sx={{ marginLeft: '60px', marginRight: '60px' }}>
						<Typography
							sx={{
								marginBottom: '40px',
							}}>
							To initiate a withdrawal, selected tokens must be withdrawn together at once.
						</Typography>

						<TableContainer>
							<Table
								sx={{
									minWidth: 650,
									border: '1px solid #d1d1d1',
									borderCollapse: 'separate',
									borderRadius: '8px',
								}}>
								<TableHead>
									<TableRow sx={{ width: '100%', backgroundColor: '#F8F8FF' }}>
										<TableCell style={{ width: '15%', fontWeight: 500, fontSize: '14px' }}>
											Token
										</TableCell>
										<TableCell style={{ width: '20%', fontWeight: 500, fontSize: '14px' }}>
											Token Value
										</TableCell>
										<TableCell
											style={{ width: '15%', fontWeight: 500, fontSize: '14px' }}
											align="left">
											USD Value
										</TableCell>
										<TableCell
											style={{ width: '50%', fontWeight: 500, fontSize: '14px' }}
											align="left">
											Send funds to
										</TableCell>
									</TableRow>
								</TableHead>

								{iAB.map((e, indx) => {
									return (
										<>
											<TableRow
												key={e.ID}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell sx={{ fontSize: '18px', verticalAlign: 'top' }}>
													<Box sx={{ display: 'flex', flexDirection: 'row' }}>
														{getTokenLogo(currencyState[e.CurrencyId].Abbreviation)}
														{currencyState[e.CurrencyId].Abbreviation}
													</Box>
												</TableCell>
												<TableCell
													sx={{ fontSize: '18px', verticalAlign: 'top' }}
													component="th"
													scope="row">
													<Box>
														{(
															e.Amount / Math.pow(10, currencyState[e.CurrencyId].Decimal)
														).toFixed(tokenDecimalLen)}{' '}
														{currencyState[e.CurrencyId].Abbreviation}
													</Box>
												</TableCell>
												<TableCell sx={{ fontSize: '18px', verticalAlign: 'top' }} align="left">
													<Box>
														$
														{calculatePrice(
															e.Amount,
															exchangeRate[e.CurrencyId],
															currencyState[e.CurrencyId].Decimal
														)}
													</Box>
												</TableCell>
												<TableCell sx={{ fontSize: '18px', verticalAlign: 'top' }} align="left">
													<StyledTextField
														margin="none"
														fullWidth
														placeholder="Token address"
														name={currencyState[e.CurrencyId].Abbreviation}
														value={e.tokenaddress}
														onChange={(event) => {
															handletokenaddress_(
																event,
																currencyState[e.CurrencyId].Abbreviation,
																indx
															);
														}}
														onBlur={() => {
															handle_focus_(indx);
														}}
													/>
													{e.showerror && e.tokenaddress.substring(0, 2) !== '0x' ? (
														<Typography
															sx={{
																fontSize: '13px',
																lineHeight: '16px',
																letterSpacing: '0.5px',
																textAlign: 'left',
																marginTop: '8px',
																color: '#ff3333',
															}}>
															Valid token address start with the characters '0x'
														</Typography>
													) : e.showerror &&
													  (e.tokenaddress.length !== 42 || !isHex(e.tokenaddress)) ? (
														<Typography
															sx={{
																fontSize: '13px',
																lineHeight: '16px',
																letterSpacing: '0.5px',
																textAlign: 'left',
																marginTop: '8px',
																color: '#ff3333',
															}}>
															Valid token address must be 42 characters long hex
														</Typography>
													) : (
														<></>
													)}
												</TableCell>
											</TableRow>
										</>
									);
								})}
							</Table>
						</TableContainer>
					</Box>
					{/* token address entry   */}
					<Box
						sx={{
							width: 'calc( 100% - 370px )',
							position: 'fixed',
							bottom: '0px',
							maxHeight: '40px',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							backgroundColor: '#FFFFFF',
							boxShadow: '0px -6px 12px rgba(0, 0, 0, 0.06)',
							padding: '32px 40px',
						}}>
						{/* <Link to='/withdraw-funds' state={{from : 'withdraw-available'}} style={{textDecoration: 'none'}}> */}
						<Box
							sx={{
								padding: '12px 30px',
								borderRadius: '4px',
								border: '1px solid rgba(81, 85, 212, 1)',
								color: 'rgba(81, 85, 212, 1)',
							}}
							onClick={cancelGoBack}>
							Cancel
						</Box>
						{/* </Link>         */}

						<Button
							style={{
								fontWeight: 500,
								height: '45px',
								textTransform: 'none',
								opacity: reviewWithdrawalDisabled ? '50%' : '100%',
								color: 'white',
							}}
							variant="contained"
							disabled={reviewWithdrawalDisabled}
							onClick={() => {
								setshownetworkdeduction(true);
							}}>
							Review withdrawal
						</Button>
					</Box>
				</>
			) : (
				<>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							marginLeft: '20px',
							marginBottom: '10px',
						}}>
						<Box>
							<img
								style={{ position: 'absolute', left: '15px', padding: '5px 10px 10px 10px' }}
								src={ArrowLeft}
								alt="info"
								width="32px"
								height="32px"
								onClick={() => {
									setshownetworkdeduction(false);
								}}
							/>

							<Typography
								variant="h4"
								sx={{
									margin: '0.5rem',
									marginLeft: '40px',
								}}>
								Review Withdrawal
							</Typography>
						</Box>
					</Box>

					<Box sx={{ marginLeft: '60px', marginRight: '60px' }}>
						<Typography
							sx={{
								fontSize: '14px',
								fontWeight: 400,
								lineHeight: '16px',
								letterSpacing: '0.5px',
								textAlign: 'left',
								marginBottom: '40px',
								color: '#767676',
							}}>
							Verify if token addresses are correct. NumePay is unable to recover funds sent to incorrect
							token addresses.
						</Typography>

						<TableContainer>
							<Table
								sx={{
									minWidth: 650,
									border: '1px solid #d1d1d1',
									borderCollapse: 'separate',
									borderRadius: '8px',
								}}>
								<TableHead>
									<TableRow sx={{ backgroundColor: '#F8F8FF' }}>
										<TableCell style={{ width: '10%', fontWeight: 500, fontSize: '14px' }}>
											Token
										</TableCell>
										<TableCell style={{ width: '25%', fontWeight: 500, fontSize: '14px' }}>
											<Typography sx={{ paddingLeft: '7px' }}>Token value</Typography>
										</TableCell>
										<TableCell
											style={{ width: '15%', fontWeight: 500, fontSize: '14px' }}
											align="left">
											USD Value
										</TableCell>
										<TableCell
											style={{ width: '50%', fontWeight: 500, fontSize: '14px' }}
											align="left">
											Send funds to
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{iAB.map((e, indx) => {
										return (
											<>
												<TableRow
													key={e.ID}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell sx={{ verticalAlign: 'top' }}>
														<Box
															sx={{
																display: 'flex',
																flexDirection: 'row',
																alignItems: 'center',
															}}>
															{getTokenLogo(currencyState[e.CurrencyId].Abbreviation)}
															<Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
																{currencyState[e.CurrencyId].Abbreviation}
															</Typography>
														</Box>
													</TableCell>
													<TableCell
														sx={{
															fontSize: '18px',
															verticalAlign: 'top',
															padding: '20px 0px 15px 15px ',
														}}
														component="th"
														scope="row">
														<Typography
															sx={{
																fontWeight: 400,
																fontSize: '16px',
																paddingLeft: '7px',
															}}>
															{(
																e.Amount /
																Math.pow(10, currencyState[e.CurrencyId].Decimal)
															).toFixed(tokenDecimalLen)}{' '}
															{currencyState[e.CurrencyId].Abbreviation}
														</Typography>

														<Typography
															sx={{
																fontWeight: 400,
																fontSize: '16px',
																marginTop: '20px',
																marginBottom: '5px',
																marginLeft: '7px',
															}}>
															Network fee{' '}
														</Typography>
														<Typography
															sx={{
																color: '#FF3333',
																fontWeight: 400,
																fontSize: '16px',
																paddingBottom: '20px',
															}}>
															-
															{(
																findnetworkfee(e.CurrencyId) /
																Math.pow(10, currencyState[e.CurrencyId].Decimal)
															).toFixed(tokenDecimalLen)}{' '}
															{currencyState[e.CurrencyId].Abbreviation}
														</Typography>
														<Box
															sx={{ borderTop: '1px solid #BDBCBC', paddingTop: '20px' }}>
															<Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
																{(
																	e.Amount /
																		Math.pow(
																			10,
																			currencyState[e.CurrencyId].Decimal
																		) -
																	findnetworkfee(e.CurrencyId) /
																		Math.pow(
																			10,
																			currencyState[e.CurrencyId].Decimal
																		)
																).toFixed(tokenDecimalLen)}{' '}
																{currencyState[e.CurrencyId].Abbreviation}
															</Typography>
														</Box>
													</TableCell>

													<TableCell
														sx={{
															fontSize: '18px',
															verticalAlign: 'top',
															padding: '20px 15px 15px 0px ',
														}}
														align="left">
														<Typography
															sx={{
																fontWeight: 400,
																fontSize: '16px',
																paddingLeft: '15px',
															}}>
															$
															{calculatePrice(
																e.Amount,
																exchangeRate[e.CurrencyId],
																currencyState[e.CurrencyId].Decimal
															)}
														</Typography>
														<Typography
															sx={{
																fontSize: '16px',
																fontWeight: 400,
																color: 'transparent',
																userSelect: 'none',
																marginTop: '20px',
																marginBottom: '5px',
																paddingLeft: '15px',
															}}>{`=`}</Typography>
														<Typography
															sx={{
																paddingBottom: '20px',
																textAlign: 'left',
																color: '#FF3333',
																fontWeight: 400,
																fontSize: '16px',
																paddingLeft: '6px',
															}}>
															-$
															{calculatePrice(
																findnetworkfee(e.CurrencyId),
																exchangeRate[e.CurrencyId],
																currencyState[e.CurrencyId].Decimal
															)}
														</Typography>
														<Box
															sx={{ borderTop: '1px solid #BDBCBC', paddingTop: '20px' }}>
															<Typography
																sx={{
																	paddingTop: '0px',
																	textAlign: 'left',
																	fontWeight: 400,
																	fontSize: '16px',
																	paddingLeft: '15px',
																}}>
																$
																{(
																	calculatePrice(
																		e.Amount,
																		exchangeRate[e.CurrencyId],
																		currencyState[e.CurrencyId].Decimal
																	) -
																	calculatePrice(
																		findnetworkfee(e.CurrencyId),
																		exchangeRate[e.CurrencyId],
																		currencyState[e.CurrencyId].Decimal
																	)
																).toFixed(usdDecimalLen)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell
														sx={{
															fontSize: '18px',
															verticalAlign: 'top',
															padding: '20px 0px 20px 20px',
														}}
														align="left">
														<Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
															{e.tokenaddress}
														</Typography>
													</TableCell>
												</TableRow>
											</>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								backgroundColor: '#F8F8FF',
								borderRadius: '4px',
								marginTop: '33px',
								marginBottom: '93px',
							}}>
							<Box
								sx={{
									width: '56%',
									padding: '20px',
									backgroundColor: '#F8F8FF',
									borderRadius: '4px',
								}}>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										marginBottom: '14px',
									}}>
									<Typography
										sx={{
											width: '60%',
											fontSize: '16px',
											fontWeight: 400,
											lineHeight: '20px',
											letterSpacing: '0.5px',
										}}>
										Initial Amount
									</Typography>
									<Typography
										sx={{
											width: '40%',
											fontSize: '16px',
											fontWeight: 400,
											lineHeight: '22px',
											letterSpacing: '0.5px',
											textAlign: 'left',
										}}>
										${totalPrice(iAB, currencyState, exchangeRate)}
									</Typography>
								</Box>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'flex-start',
										padding: '10px 0px',
										borderBottom: '0.5px solid rgba(154, 154, 154, 1)',
									}}>
									<Box sx={{ width: '59%' }}>
										<Typography
											sx={{
												fontSize: '16px',
												fontWeight: 400,
												lineHeight: '20px',
												letterSpacing: '0.5px',
												marginRight: '10px',
											}}>
											Network Fee
										</Typography>

										<Typography
											sx={{
												width: '69%',
												marginTop: '8px',
												fontWeight: 500,
												fontSize: '14px',
												lineHeight: '18px',
												color: '#767676',
											}}>
											All cryptocurrency transactions incur a processing fee to pay miners.
											<Typography
												sx={{
													display: 'inline-block',
													fontWeight: 500,
													fontSize: '14px',
													lineHeight: '18px',
													color: '#0066C0',
													textDecoration: 'underline',
												}}>
												Learn more about network fees
											</Typography>
										</Typography>
									</Box>
									<Box sx={{ width: '41%' }}>
										<Typography
											sx={{
												fontSize: '16px',
												fontWeight: 400,
												lineHeight: '22px',
												letterSpacing: '0.5px',
												textAlign: 'left',
												color: '#FF3333',
											}}>
											-${totalPrice(networkFee, currencyState, exchangeRate)}
										</Typography>
										{/* <Typography sx={{ marginTop: '5px' }}>0.000665 ETH</Typography> */}
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
										marginTop: '14px',
									}}>
									<Typography
										sx={{
											width: '59%',
											fontSize: '18px',
											fontWeight: 500,
											lineHeight: '24px',
											letterSpacing: '0.5px',
										}}>
										Final withdrawal amount
									</Typography>
									<Typography
										sx={{
											width: '41%',
											fontSize: '18px',
											fontWeight: 500,
											lineHeight: '24px',
											letterSpacing: '0.5px',
											textAlign: 'left',
										}}>
										$
										{(
											totalPrice(iAB, currencyState, exchangeRate) -
											totalPrice(networkFee, currencyState, exchangeRate)
										).toFixed(2)}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
					{/* token address entry   */}
					<Box
						sx={{
							width: 'calc( 100% - 370px )',
							position: 'fixed',
							bottom: '0px',
							// maxHeight:'40px',
							marginTop: '36px',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							backgroundColor: '#FFFFFF',
							boxShadow: '0px -6px 12px rgba(0, 0, 0, 0.06)',
							padding: '32px 40px',
						}}>
						<Link
							to="/withdraw-funds"
							state={{ from: 'withdraw-available' }}
							style={{ textDecoration: 'none' }}>
							<Box
								sx={{
									padding: '12px 30px',
									borderRadius: '4px',
									border: '1px solid rgba(81, 85, 212, 1)',
									color: 'rgba(81, 85, 212, 1)',
								}}>
								Cancel
							</Box>
						</Link>

						{/* <Link
							to={'/withdraw-confirmation'}
							state={{
								iAB,
								currencyState,
								exchangeRate,
								networkFee,
								initialAmount: totalPrice(iAB, currencyState, exchangeRate),
								totalNetworkFee: totalPrice(networkFee, currencyState, exchangeRate),
							}}
							style={{ textDecoration: 'none' }}> */}
						<CustomButton
							style={{
								fontWeight: 500,
								height: '45px',
								textTransform: 'none',
							}}
							variant="contained"
							disabled={false}
							isLoading={widthdawalLoading}
							onClick={() => {
								sendTokenData();
							}}>
							Confirm withdrawal
						</CustomButton>
						{/* </Link> */}
					</Box>
				</>
			)}

			<Dialog onClose={handleClose} open={open}>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '15px' }}>
					<CloseIcon sx={{ marginRight: '10px' }} onClick={handleClose} />
				</Box>
				<DialogTitle>
					<Typography
						sx={{
							fontSize: '24px',
							fontWeight: 500,
							lineHeight: '29px',
							textAlign: 'center',
						}}>
						Are you sure?
					</Typography>
				</DialogTitle>
				<DialogContent sx={{ width: '370px' }}>
					<Typography
						sx={{
							fontSize: '18px',
							fontWeight: 500,
							lineHeight: '24px',
							textAlign: 'center',
						}}>
						If you leave now, your withdrawal progress won’t be saved.
					</Typography>
				</DialogContent>
				<DialogActions sx={{ display: 'flex', flexDirection: 'column' }}>
					<Button
						sx={{
							backgroundColor: '#5155D4',
							'&:hover': { backgroundColor: '#5155D4' },
							color: 'white',
							marginBottom: '10px',
							textTransform: 'none',
						}}
						onClick={handleClose}>
						No, Cancel
					</Button>
					<Link to="/withdraw-funds" style={{ textDecoration: 'none' }}>
						<Button sx={{ border: '1px solid #5155D4', marginBottom: '10px', textTransform: 'none' }}>
							Exit the page
						</Button>
					</Link>
				</DialogActions>
			</Dialog>

			<Tooltip
				heading=""
				showheading={false}
				anchorEl={networkanchor}
				handleClose={handlenetworkfeeclose}
				content={<Typography>The amount available to be withdrawn from your account.</Typography>}
				horizontalOrientation="left"
			/>
		</Box> //outermost
	);
}

export default WithdrawAvailable;
