import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	Box,
	Typography,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { tokenDecimalLen, calculatePrice, usdDecimalLen, getTokenLogo, convertToWei } from '../../utils';
import { ReactComponent as WithdrawaIcon } from '../../assets/widthdrawal_Icon.svg';

function WithdrawConfirmation() {
	const location = useLocation();
	const { currencyState, exchangeRate, initialAmount, currencyId, chain, txhash } = location.state;
	console.log('------------------->', location);

	const nativeCurrencyAddress = useCallback(() => {
		const currencies = Object.values(currencyState);
		for (let i = 0; i < currencies.length; i++) {
			if (currencies[i].Native === true) {
				console.log(' Native found ', currencies[i]);
				return currencies[i];
			}
		}
		return {};
	}, [currencyState]);

	const nativeCurrency = nativeCurrencyAddress();

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					margin: '20px',
					marginBottom: '40px',
				}}>
				{/* <Box>
                <img src={ArrowLeft} width='32px' height='32px' onClick={()=>{setshownetworkdeduction(false)}}/>
             </Box> */}

				<Typography
					sx={{
						margin: '0.5rem',
						marginLeft: '42px',
						fontWeight: 700,
						fontSize: '24px',
					}}>
					Withdrawal Confirmation
				</Typography>
			</Box>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					marginLeft: '60px',
					marginRight: '60px',
					backgroundColor: ' #F8F8FF',
					padding: '60px 42px',
					borderRadius: '4px',
				}}>
				<Box sx={{ width: '60%', display: 'flex', flexDirection: 'row' }}>
					<span>
						<WithdrawaIcon
							style={{ width: '73px', height: '75px', paddingLeft: '0px', verticalAlign: 'center' }}
						/>
					</span>
					<Box
						sx={{
							marginLeft: '20px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							alignItem: 'flex-start',
						}}>
						<Typography
							sx={{
								color: '#0EA90B',
								fontSize: '24px',
								fontWeight: 600,
								lineHeight: '29px',
							}}>
							Withdrawal initiated
						</Typography>
						<Typography
							sx={{
								fontSize: '16px',
								fontWeight: 500,
								lineHeight: '24px',
							}}>
							Sit tight, your funds are on their way!
						</Typography>
					</Box>
				</Box>

				<Box sx={{ width: '40%' }}>
					<Typography
						sx={{
							fontSize: '16px',
							fontWeight: 500,
							lineHeight: '20px',
							marginBottom: '12px',
						}}>
						Final Withdrawal Amount
					</Typography>
					<Typography
						sx={{
							fontSize: '32px',
							fontWeight: 600,
							lineHeight: '39px',
						}}>
						${(initialAmount / Math.pow(10, currencyState[currencyId].Decimal)).toFixed(usdDecimalLen)}
					</Typography>
				</Box>
			</Box>

			<Box sx={{ marginLeft: '60px', marginRight: '60px' }}>
				<Typography
					sx={{
						marginTop: '16px',
						marginBottom: '16px',
						fontSize: '18px',
						lineHeight: '24px',
						fontWeight: 700,
					}}>
					Funds withdrawn:
				</Typography>

				<TableContainer>
					<Table
						sx={{
							minWidth: 650,
							border: '1px solid #d1d1d1',
							borderCollapse: 'separate',
							borderRadius: '8px',
						}}>
						<TableHead>
							<TableRow sx={{ backgroundColor: '#F8F8FF' }}>
								<TableCell style={{ width: '15%', fontWeight: 500, fontSize: '16px' }}>Token</TableCell>
								<TableCell style={{ width: '20%', fontWeight: 500, fontSize: '16px' }}>
									Token value
								</TableCell>
								<TableCell style={{ width: '20%', fontWeight: 500, fontSize: '16px' }} align="left">
									USD Value
								</TableCell>
								<TableCell style={{ width: '20%', fontWeight: 500, fontSize: '16px' }} align="left">
									Nume Fees
								</TableCell>
								<TableCell style={{ width: '45%', fontWeight: 500, fontSize: '16px' }} align="left">
									Send funds to
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							<>
								<TableRow key={txhash} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell sx={{ verticalAlign: 'top' }}>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												fontSize: '18px',
												verticalAlign: 'top',
											}}>
											{getTokenLogo(currencyState[currencyId].Abbreviation)}
											<Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
												{currencyState[currencyId].Abbreviation}
											</Typography>
										</Box>
									</TableCell>
									<TableCell
										sx={{
											fontSize: '18px',
											verticalAlign: 'top',
											padding: '20px 0px 20px 15px',
										}}
										align="left">
										<Typography sx={{ fontWeight: 400, fontSize: '18px' }}>
											{(initialAmount / Math.pow(10, currencyState[currencyId].Decimal)).toFixed(
												tokenDecimalLen
											)}
										</Typography>
									</TableCell>
									<TableCell
										sx={{
											fontSize: '18px',
											verticalAlign: 'top',
											padding: '20px 0px 20px 15px',
										}}
										align="left">
										<Typography sx={{ fontWeight: 400, fontSize: '18px' }}>
											$
											{calculatePrice(
												initialAmount,
												exchangeRate[currencyId],
												currencyState[currencyId].Decimal
											)}
										</Typography>
									</TableCell>
									<TableCell
										sx={{
											fontSize: '18px',
											verticalAlign: 'top',
											padding: '20px 0px 20px 15px',
										}}
										align="left">
										<Typography sx={{ fontWeight: 400, fontSize: '18px' }}>
											<div>
												{' '}
												{(
													convertToWei(
														nativeCurrency.DefaultWithdrawalFees,
														nativeCurrency.Decimal
													) / Math.pow(10, nativeCurrency.Decimal)
												).toFixed(tokenDecimalLen)}{' '}
												{nativeCurrency.Abbreviation}
											</div>

											<div style={{ color: '#767676' }}>
												$
												{calculatePrice(
													convertToWei(
														nativeCurrency.DefaultWithdrawalFees,
														nativeCurrency.Decimal
													),
													exchangeRate[nativeCurrency.ID],
													currencyState[nativeCurrency.ID].Decimal
												)}
											</div>
										</Typography>
									</TableCell>
									<TableCell sx={{ verticalAlign: 'top' }}>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
											}}>
											{getTokenLogo('MATIC')}
											<Typography sx={{ fontWeight: 400, fontSize: '18px' }}>{chain}</Typography>
										</Box>
									</TableCell>
								</TableRow>
							</>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
			{/* token address entry   */}

			<Box
				sx={{
					width: 'calc(100% - 370px)',
					position: 'fixed',
					bottom: '0px',
					// maxHeight:'40px',
					marginTop: '36px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					alignItems: 'center',
					backgroundColor: '#FFFFFF',
					boxShadow: '0px -6px 12px rgba(0, 0, 0, 0.06)',
					padding: '32px 40px',
				}}>
				<Link
					to={'/withdraw-funds'}
					state={{ from: 'withdraw-confirmation' }}
					style={{ textDecoration: 'none' }}>
					<Button
						style={{
							height: '45px',
							fontWeight: 500,
							fontSize: '16px',
							textTransform: 'none',
							padding: '8px 78px',
							backgroundColor: ' #5155D4',
							color: '#FFFFFF',
						}}
						variant="contained"
						disabled={false}>
						Close
					</Button>
				</Link>
			</Box>
		</>
	);
}

export default WithdrawConfirmation;
