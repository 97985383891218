import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/nume-business-logo.png';
import { toast } from 'react-toastify';
import LinkSent from '../../assets/link-sent.png';

export const VerifyEmail = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	/* 	const handleResendEmail = async () => {
		try {
			await Auth.resendSignUp(state.email);
			toast.success('Verification email resent');
		} catch (err) {
			toast.error('Error in sending verification email');
		}
	}; */
	return (
		<div>
			<img
				src={Logo}
				alt="logo"
				style={{
					margin: '20px 40px',
					position: 'absolute',
					maxWidth: '200px',
				}}
			/>
			<Container
				style={{
					minHeight: '100vh',
					minWidth: '100vw',
					display: 'flex',
				}}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						margin: 'auto',
					}}>
					<Box
						sx={{
							// height: '250px',
							width: '600px',
							padding: '20px',
							border: '1px solid #E8E8E8',
							borderRadius: '4px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							p: 4,
							boxShadow: ' 0px 4px 12px rgba(0, 0, 0, 0.08);',
							// alignItems: 'center',
						}}>
						<>
							<div style={{ textAlign: 'center' }}>
								<Typography component="h1" variant="h4" sx={{ pb: 2, fontSize: '28px' }}>
									Verify your email
								</Typography>
								<Typography variant="body1" sx={{ color: '#484848' }}>
									We have sent a verification email to{' '}
									<span style={{ fontWeight: '500', color: '#1A1A1A' }}>{state?.email}</span> . Please
									click the link sent to get started.
								</Typography>
							</div>
							<img
								src={LinkSent}
								alt="LinkSent"
								style={{ width: '240px', margin: 'auto', padding: '20px' }}
							/>
							<Typography variant="body1" sx={{ textAlign: 'center', color: '#484848' }}>
								Didn't get the mail? Please check your spam folder. In case you haven't found it, don't
								worry!{' '}
								<span style={{ color: '#0066C0', fontWeight: 500 }} onClick={''}>
									Resend verification email
								</span>
							</Typography>
							<span
								style={{
									color: '#0066C0',
									fontWeight: 500,
									textAlign: 'center',
									marginTop: '16px',
									cursor: 'pointer',
								}}
								onClick={() => {
									navigate('/', { state: { showSignup: true } });
								}}>
								Sign up with another email
							</span>
						</>
					</Box>
				</Box>
			</Container>
		</div>
	);
};
