import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Grid, FormControl, Select, MenuItem, FormHelperText, Autocomplete } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { StyledTextField } from '../../components/StyledTextField';
import ReactFlagsSelect from 'react-flags-select';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { toast } from 'react-toastify';
const useHelperTextStyles = makeStyles(() => ({
	root: {
		marginLeft: 0,
	},
}));
const autocompleteService = { current: null };
const countryStatePicker = require('country-state-picker');
export const AddressDetails = ({
	streetAddress,
	setStreetAddress,
	zipCode,
	setZipCode,
	city,
	setCity,
	appartment,
	setAppartment,
	country,
	setCountry,
	addressError,
	region,
	setRegion,
	focusCounter,
	focusTracker,
	setFocusCounter,
	setFocusTracker,
}) => {
	function fillInAddress(place) {
		var locationInfo = {
			geo: '',
			country: '',
			state: '',
			city: '',
			postalCode: '',
			street: '',
			streetNumber: '',
		};

		locationInfo.geo = [place.geometry.location.lat(), place.geometry.location.lng()];
		let address = place.address_components;
		for (var i = 0; i < address.length; i++) {
			var component = address[i].types[0];
			switch (component) {
				case 'country':
					locationInfo.country = address[i]['long_name'];
					break;
				case 'administrative_area_level_1':
					locationInfo.state = address[i]['long_name'];
					break;
				case 'locality':
					locationInfo.city = address[i]['long_name'];
					break;
				case 'postal_code':
					locationInfo.postalCode = address[i]['long_name'];
					break;
				case 'route':
					locationInfo.street = address[i]['long_name'];
					break;
				case 'street_number':
					locationInfo.streetNumber = address[i]['long_name'];
					break;
				default:
					break;
			}
		}
		setRegion(locationInfo.state);
		setCity(locationInfo.city);
		setZipCode(locationInfo.postalCode);
		setStreetAddress(locationInfo.streetNumber + ', ' + locationInfo.street);
		setAppartment('');
	}
	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState([]);
	const [mapErrorSeen, setMapErrorSeen] = useState(false);

	const fetch = useMemo(
		() =>
			debounce((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 400),
		[]
	);

	useEffect(() => {
		let active = true;

		if (!autocompleteService.current && window.google) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}

		const searchOptions = {
			componentRestrictions: { country: ['us'] },
			fields: ['address_components', 'geometry'],
			types: ['address'],
		};

		fetch({ input: inputValue, ...searchOptions }, (results) => {
			console.log(results);
			if (active) {
				let newOptions = [];
				if (value) {
					newOptions = [value];
				}
				if (results) {
					newOptions = [...newOptions, ...results];
				}
				setOptions(newOptions);
			}
		});
		if (options.length === 0 && !mapErrorSeen) {
			setMapErrorSeen(true);
			setStreetAddress(inputValue);
			toast.error('No results found');
		}

		return () => {
			active = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, inputValue, fetch]);
	const helperTextStyles = useHelperTextStyles();
	return (
		<Box
			sx={{
				margin: 2,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
			<Typography
				component="h3"
				variant="h3"
				style={{
					marginBottom: '10px',
					textAlign: 'left',
					width: '100%',
					fontSize: '32px',
					fontWeight: 700,
				}}>
				Enter address of your business
			</Typography>
			<Typography
				component="h5"
				variant="h5"
				style={{
					marginBottom: '24px',
					color: '#484848',
					textAlign: 'left',
					width: '100%',
					fontWeight: 400,
				}}>
				Your address is an essential datapoint to help us verify your business.
			</Typography>
			<Box sx={{ mt: 1, width: '100%' }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							Country
						</Typography>
						<ReactFlagsSelect
							selected={country}
							disabled
							onSelect={(code) => setCountry(code)}
							style={{ height: '40px' }}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							Street Address 1
						</Typography>
						<StyledTextField
							sx={{ mt: 0.5 }}
							margin="none"
							fullWidth
							id="street"
							placeholder="Add a location"
							name="street"
							onBlur={() => {
								setFocusTracker({ ...focusCounter, street: false });
								setFocusCounter({ ...focusCounter, street: 1 });
							}}
							onFocus={() => setFocusTracker({ ...focusCounter, street: true })}
							error={addressError['street']?.error && focusCounter['street'] >= 1}
							helperText={
								addressError['street']?.error && focusCounter['street'] >= 1
									? addressError['street'].message
									: null
							}
							FormHelperTextProps={{
								classes: {
									root: helperTextStyles.root,
								},
							}}
							autoComplete="street"
							value={streetAddress}
							onChange={(e) => setStreetAddress(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							Street address 2
						</Typography>
						<StyledTextField
							sx={{ mt: 0.5 }}
							margin="none"
							fullWidth
							id="appartment"
							placeholder="Apartment #, Unit"
							name="appartment"
							onBlur={() => {
								setFocusTracker({ ...focusCounter, appartment: false });
								setFocusCounter({ ...focusCounter, appartment: 1 });
							}}
							onFocus={() => setFocusTracker({ ...focusCounter, appartment: true })}
							error={addressError['appartment']?.error && focusCounter['appartment'] >= 1}
							helperText={
								addressError['appartment']?.error && focusCounter['appartment'] >= 1
									? addressError['appartment'].message
									: null
							}
							FormHelperTextProps={{
								classes: {
									root: helperTextStyles.root,
								},
							}}
							autoComplete="appartment"
							value={appartment}
							onChange={(e) => setAppartment(e.target.value)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							City
						</Typography>
						<StyledTextField
							sx={{ mt: 0.5 }}
							margin="none"
							fullWidth
							id="city"
							placeholder="Colby"
							name="city"
							onBlur={() => {
								setFocusTracker({ ...focusCounter, city: false });
								setFocusCounter({ ...focusCounter, city: 1 });
							}}
							onFocus={() => setFocusTracker({ ...focusCounter, city: true })}
							error={addressError['city']?.error && focusCounter['city'] >= 1}
							helperText={
								addressError['city']?.error && focusCounter['city'] >= 1
									? addressError['city'].message
									: null
							}
							FormHelperTextProps={{
								classes: {
									root: helperTextStyles.root,
								},
							}}
							autoComplete="city"
							value={city}
							onChange={(e) => setCity(e.target.value)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							State
						</Typography>
						<FormControl
							sx={{ width: '100%' }}
							error={addressError['region']?.error && focusCounter['region'] >= 1}>
							<Select
								value={region}
								sx={{
									width: '100%',
									height: '40px',
									maxHeight: '200px',
									'& .MuiSelect-select': { padding: '12px !important' },
									mt: 0.5,
								}}
								onChange={(e) => {
									console.log(focusCounter);
									setFocusCounter({ ...focusCounter, region: 1 });
									setRegion(e.target.value);
								}}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}>
								<MenuItem value="" disabled>
									Select state
								</MenuItem>
								{countryStatePicker.getStates(country.toLocaleLowerCase()).map((e) => {
									return (
										<MenuItem key={e} value={e}>
											{e}
										</MenuItem>
									);
								})}
							</Select>
							{addressError['region']?.error && focusCounter['region'] >= 1 ? (
								<FormHelperText sx={{ ml: 0 }}>{addressError['region']?.message}</FormHelperText>
							) : (
								<></>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<Typography
							component="h6"
							variant="h6"
							sx={{ textAlign: 'left', color: '#767676', fontSize: '16px', mt: 1 }}>
							Zipcode
						</Typography>
						<StyledTextField
							sx={{ mt: 0.5 }}
							margin="none"
							fullWidth
							id="zip-code"
							placeholder="00000"
							name="zip-code"
							onBlur={() => {
								setFocusTracker({ ...focusCounter, zipCode: false });
								setFocusCounter({ ...focusCounter, zipCode: 1 });
							}}
							onFocus={() => setFocusTracker({ ...focusCounter, zipCode: true })}
							error={addressError['zipCode']?.error && focusCounter['zipCode'] >= 1}
							helperText={
								addressError['zipCode']?.error && focusCounter['zipCode'] >= 1
									? addressError['zipCode'].message
									: null
							}
							FormHelperTextProps={{
								classes: {
									root: helperTextStyles.root,
								},
							}}
							autoComplete="zip-code"
							value={zipCode}
							onChange={(e) => setZipCode(e.target.value)}
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
