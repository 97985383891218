import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Box,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Typography,
	ButtonBase,
	Paper,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { addMerchant } from '../../actions/merchant';
import { unsetApiToken } from '../../actions/apiTokenActions';
import { ReactComponent as SettingsLogo } from '../../assets/settings.svg';
import { ReactComponent as HomeLogo } from '../../assets/home.svg';
import { ReactComponent as SalesLogo } from '../../assets/sales.svg';
import { ReactComponent as WalletLogo } from '../../assets/wallet.svg';
import { ReactComponent as LogoutLogo } from '../../assets/logout.svg';
import { ReactComponent as DropDown } from '../../assets/drop-down.svg';
import { ReactComponent as Disconnect } from '../../assets/disconnect.svg';
import NumeBusinessLogo from '../../assets/nume-business-logo.png';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount, useDisconnect } from 'wagmi';
import { Logout } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	children: {
		marginLeft: '290px',
		width: '100%',
	},
	bottomList: {
		position: 'absolute !important',
		bottom: 0,
		width: '100%',
		paddingBottom: '16px !important',
	},
	active: {
		color: '#5155D4',
		fill: '#5155D4',
		fontWeight: '500 !important',
		minWidth: '40px !important',
	},
	listItem: {
		color: '#373737',
		fontWeight: '500 !important',
		minWidth: '40px !important',
	},
	navbaractive: {
		backgroundColor: 'blue',
		border: '1px solid black',
	},
}));

const topMenuList = () => {
	return [
		{
			name: 'Home',
			link: ['/dashboard'],
			icon: <HomeLogo />,
		},
		{
			name: 'Sales overview',
			link: ['/sales-overview'],
			icon: <SalesLogo />,
		},
		{
			name: 'Balance',
			link: ['/withdraw-funds', '/withdraw-available', '/withdraw-confirmation'],
			icon: <WalletLogo />,
		},
		{
			name: 'Settings',
			link: ['/settings'],
			icon: <SettingsLogo />,
		},
	];
};

const bottomMenuList = (handleLogout) => {
	return [
		{
			name: 'Settings',
			link: '/settings',
			icon: <SettingsLogo />,
		},
		{
			name: 'Logout',
			link: '/logout',
			icon: <LogoutLogo />,
			onClick: async () => {
				await handleLogout();
			},
		},
	];
};

function useOutsideAlerter(ref1, ref2, callBack) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (
				ref1.current &&
				!ref1.current.contains(event.target) &&
				ref2.current &&
				!ref2.current.contains(event.target)
			) {
				callBack();
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref1, ref2]);
}

export const Navbar = (props) => {
	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { address, isConnected } = useAccount();
	const { disconnect } = useDisconnect({
		onSuccess(data) {
			console.log('Disconnected');
			dispatch(addMerchant({}));
			dispatch(unsetApiToken());
			navigate('/');
		},
	});
	const [showDisconnect, setShowDisconnect] = useState(false);

	const handleLogout = async () => {
		disconnect();
		//await Auth.signOut();
		navigate('/');
	};

	const ref1 = useRef(null);
	const ref2 = useRef(null);
	useOutsideAlerter(ref1, ref2, () => {
		setShowDisconnect(false);
	});

	const list = () => (
		<Box sx={{ width: 290, height: '100%', background: '#FFFFFF' }} role="presentation">
			<div style={{ paddingTop: '16px', margin: 'auto', background: '#F8F8FF' }}>
				<img
					onClick={() => navigate('/dashboard')}
					src={NumeBusinessLogo}
					alt="nume-business-logo"
					style={{
						maxWidth: '200px',
						paddingLeft: '12px',
					}}
				/>{' '}
			</div>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					justifyContent: 'center',
					pl: '24px',
					bgcolor: '#F8F8FF',
					py: '32px',
				}}>
				{address ? (
					<ButtonBase
						sx={{
							boxShadow: '0 4px 4px rgb(0 0 0 / 0.2)',
							borderRadius: '10px',
							py: '8px',
							px: '16px',
							bgcolor: '#FFFFFF',
						}}
						onClick={() => {
							setShowDisconnect(!showDisconnect);
						}}
						ref={ref1}>
						<Typography
							sx={{
								fontWeight: 700,
								fontSize: '18px',
								lineHeight: '24px',
							}}
							color="#000000">
							{`${address.slice(0, 4)}...${address.slice(-4)}`}
						</Typography>
						<DropDown style={{ marginLeft: 32 }} />
					</ButtonBase>
				) : null}

				<Box
					sx={{
						position: 'absolute',
						visibility: showDisconnect ? 'visible' : 'hidden',
					}}
					mt={11}
					ref={ref2}>
					<ButtonBase
						sx={{
							boxShadow: '0 4px 4px rgb(0 0 0 / 0.1)',
							borderRadius: '4px',
							py: '12px',
							px: '16px',
							pr: '56px',
							bgcolor: '#FFFFFF',
						}}
						onClick={() => {
							setShowDisconnect(false);
							handleLogout();
						}}>
						<Disconnect />
						<Typography
							sx={{
								fontWeight: 400,
								fontSize: '16px',
								lineHeight: '16px',
							}}
							color="rgba(16, 21, 33, 0.6)"
							ml={1}>
							Disconnect
						</Typography>
					</ButtonBase>
				</Box>
			</Box>

			<List style={{ paddingTop: '48px' }}>
				{topMenuList().map((e, i) => {
					return (
						<ListItem
							key={i}
							sx={{
								backgroundColor: e.link.includes(location.pathname) ? '#EFEFFB' : '',
								borderRight: e.link.includes(location.pathname) ? '2px solid #5155D4' : '',
								padding: '16px',
							}}
							button
							onClick={() => (e.onClick ? e.onClick() : navigate(e.link[0]))}>
							<ListItemIcon
								className={e.link.includes(location.pathname) ? classes.active : classes.listItem}>
								{e.icon}
							</ListItemIcon>
							<ListItemText
								className={e.link.includes(location.pathname) ? classes.active : classes.listItem}>
								<span
									className={e.link.includes(location.pathname) ? classes.active : classes.listItem}>
									{e.name}
								</span>
							</ListItemText>
						</ListItem>
					);
				})}
			</List>
			{/* <List className={classes.bottomList}>
				<Divider sx={{ margin: '16px' }} />
				{bottomMenuList(handleLogout).map((e, i) => {
					return (
						<ListItem
							key={i}
							button
							onClick={() => (e.onClick ? e.onClick() : navigate(e.link))}
							sx={{
								backgroundColor: e.link === location.pathname ? '#EFEFFB' : '',
								borderRight: e.link === location.pathname ? '2px solid #5155D4' : '',
								padding: '16px',
							}}>
							<ListItemIcon className={location.pathname === e.link ? classes.active : classes.listItem}>
								{e.icon}
							</ListItemIcon>
							<ListItemText className={location.pathname === e.link ? classes.active : classes.listItem}>
								<span className={location.pathname === e.link ? classes.active : classes.listItem}>
									{e.name}
								</span>
							</ListItemText>
						</ListItem>
					);
				})}
			</List> */}
		</Box>
	);

	return (
		<div className={classes.root}>
			<Drawer variant="permanent">{list()}</Drawer>
			<div className={classes.children}>{props.children}</div>
		</div>
	);
};
